import Parent, { ParentModel } from "./Parent"
import Child, { ChildModel } from "./Child"
import BankDetails, { BankDetailsModel } from './BankDetails'
import { DonationSourceAccount } from "../../../pages/charity/api";
import Donation, { DonationModel } from "./Donation";


export interface StoreModel {
    Parent: ParentModel
    Child: ChildModel
    BankDetails: BankDetailsModel
    Donation: DonationModel
}

const model: StoreModel = {
    Parent,
    Child,
    BankDetails,
    Donation
}

export default model;