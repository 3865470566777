
import { Action, action } from "easy-peasy"

export interface BankDetailsModel {
    BSB: string
    account: string
    name: string
    bank: string
  
    setBSB: Action<BankDetailsModel, string>
    setaccount: Action<BankDetailsModel, string>
    setname: Action<BankDetailsModel, string>
    setbank: Action<BankDetailsModel, string>
}


const Child: BankDetailsModel = {
    BSB: "",
    account : "",
    name: "",
    bank: "",


    setBSB: action((state, payload) => {
        state.BSB = payload
    }),
    setaccount: action((state, payload) => {
        state.account = payload
    }),   
    setname: action((state, payload) => {
        state.name = payload
    }),  
    setbank: action((state, payload) => {
        state.bank = payload
    }),                 
}

export default Child;