import React, { useState, useContext, useRef } from 'react'

import { useStoreActions, Actions, useStoreState } from 'easy-peasy'
import { StoreModel } from '../../common/contexts/model/Index'

import {__RouterContext} from "react-router"
import CardView from '../../components/CardView/CardView'
import FilledButton from '../../components/FilledButton/FilledButton'
import SignUpManager from './SignUpManager'
import * as CSS from 'csstype'
import '../../components/Page/Page.css'
import Style from '../../common/style/Style'
import useTitle from '../../common/useTitle'

import InputValidator from '../../common/utils/InputValidator'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import logo1x from '../../assets/images/image1x/logo-full-148.png'
import logo2x from '../../assets/images/image2x/logo-full-148.png'
import logo3x from '../../assets/images/image3x/logo-full-148.png'

const cardStyle: CSS.Properties = {
    paddingLeft: '19px',
    paddingRight: '19px'
}

const titleStyle: CSS.Properties = {
    marginTop: '32px'
}

const alertTitleStyle: CSS.Properties = {
  fontFamily: 'Bariol-Bold' 
}
const alertDescriptionStyle: CSS.Properties = {
  fontFamily: 'Bariol' 
}

const EnterMobileNumberPage: React.FC = () => {

  const inputElement = useRef<HTMLInputElement>(null)
  const routerContext = useContext(__RouterContext)


  const savedMobile = useStoreState(state => state.Parent).mobile

  useTitle('Sign Up Spriggy')

  const [errorMessage, setErrorMessage] = useState('' as string | undefined)
  const [hintMessage, setHintMessage] = useState('' as string | undefined)
  const [pristine, setPristine] = useState(true)
  const [saving, setSaving] = useState(false)
  const [mobileNumber, setMobileNumber] = useState(savedMobile)
  const [open, setOpen] = React.useState(false)

  const storeSetMobile = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setMobile)

  const handleNewMobileNumber = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    const numberDigits = value.replace(/[^0-9]/g, '')
    const {hint, error} = InputValidator.mobileValidation(numberDigits)
    setMobileNumber(numberDigits)
    setHintMessage('')
    setErrorMessage('')
    if (!pristine && numberDigits.length === 0) {
      setErrorMessage('Required field')
    }
    if (pristine && numberDigits.length > 0) {
      setPristine(false)
    }
    if (numberDigits.length > 0) {
      setHintMessage(hint)
      setErrorMessage(error)
    }
  }

  function handleClose() {
    setOpen(false)
  }

  return (
      <div className="Page" id="enterMobileNumberPage">

        <CardView style={cardStyle}>

          <img src={logo1x} alt="Spriggy, Pocket Money Updated" srcSet={`${logo2x} 2x, ${logo3x} 3x`}/>

          <p className="TitleLabel" style={titleStyle}>
            Enter your mobile number to start
          </p>

          <p style={{marginBottom: '25px', fontSize: '17px', lineHeight: '20px', color: '#333b44'}}>
            We’ll send an SMS with a code to confirm the mobile number belongs to you
          </p>

          <input 
            ref={inputElement}
            className="PlainInput" 
            style={{...Style.inputStandardStyle, paddingBottom: '15px' }} 
            type="number"
            pattern="\d*"
            maxLength={10}
            onChange={handleNewMobileNumber}
            placeholder="Mobile Number"
            value={mobileNumber}
            id="enterMobileInput"
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            autoFocus={true}
          />
          {(!!hintMessage) ? (
            <p style={{color: '#999999', textAlign: 'left', marginTop: '5px'}}>{hintMessage}</p>
          ) : ('')}
          {(!!errorMessage) ? (
            <p style={{color: '#FF0000', textAlign: 'left', marginTop: '5px'}}>{errorMessage}</p>
          ) : ('')}

          <div style={Style.spaceStyle}></div>

          <FilledButton 
            title="Next" 
            action={() => {
              if (!errorMessage) {
                setOpen(false)
                setSaving(true)
                SignUpManager
                  .getInstance()
                  .saveMobile(mobileNumber)
                  .then(() => {
                    storeSetMobile(mobileNumber)
                    setTimeout(() => routerContext.history.push("/signup/verifyMobileNumber"), 0)
                  })
                  .catch((errorMessage) => {
                    storeSetMobile('')
                    setErrorMessage(errorMessage)
                    setOpen(true)
                    setMobileNumber("")
                  })
                  .finally(() => {
                    setSaving(false)
                  })
              } else {
                setSaving(false)
                setOpen(true)
              }
            }}
            invalid={(pristine || !!errorMessage || !!hintMessage || saving)}
          />
          </CardView>
        <div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <DialogTitle id="alert-dialog-title"><div style={alertTitleStyle}>Invalid number entered</div></DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <span style={alertDescriptionStyle}>{errorMessage}</span>      
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              <span style={alertTitleStyle}> Try Again</span>           
            </Button>
          </DialogActions>

        </Dialog>

      </div>
    </div>
  )
}

export default EnterMobileNumberPage