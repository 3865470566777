import React, { useEffect, useState, useContext } from 'react'
import {__RouterContext} from "react-router"
import CardView from '../../components/CardView/CardView'
import PaymentManager, { UnifiedPaymentType } from './PaymentManager'
import * as CSS from 'csstype'
import '../../components/Page/Page.css'

import bankAccount1x from '../../assets/images/image1x/bank.png'
import bankAccount2x from '../../assets/images/image2x/bank.png'
import bankAccount3x from '../../assets/images/image3x/bank.png'
import debitCard1x from '../../assets/images/image1x/debit_card.png'
import debitCard2x from '../../assets/images/image2x/debit_card.png'
import debitCard3x from '../../assets/images/image3x/debit_card.png'
import paypal1x from '../../assets/icon/icon-paypal-square-43.png'
import paypal2x from '../../assets/icon/icon-paypal-square-43@2x.png'
import paypal3x from '../../assets/icon/icon-paypal-square-43@3x.png'
import { postAnalytics } from '../../common/postMessage'
import useTitle from '../../common/useTitle'
import Spinner from '../../components/Spinner/Spinner'

const titleStyle: CSS.Properties = {
    fontFamily: 'Bariol-Bold',
    fontSize: '13px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#767676',
    margin: "0",
    marginBottom:"22px"
}

export interface Props {
    action?: () => void
}

const ConnectedAccountsPage: React.FC<Props> = ({ action }) => {

    const routerContext = useContext(__RouterContext)

    const [accountType, setAccountType] = useState<UnifiedPaymentType | null>(null)

    useTitle('Connected Account')

    useEffect(() => {
        PaymentManager.getInstance().getSavedMethod().then(({type, last4}) => {
            if (type !== null) {
                setAccountType(type)
            } else {
                routerContext.history.replace('/payment/addDebitCard')
            }
        }).catch(error => {
            // alert('Unfortunately there was a technical issue trying to connect an account. We are working on trying to resolve the cause. In the mean time you can contact support if you need help.')
            // postDismiss()
        })
    }, [routerContext.history])

    useEffect(() => {
        postAnalytics('PWA Connected Account')
    }, [])

    return (
        <div className="Page" id="ConnectedAccountsPage">

            <CardView style={{padding:"16px",paddingTop:"24px"}}>

                <p style={titleStyle}>CURRENT CONNECTED ACCOUNT</p>

                {/* Loading */}

                <div hidden={ accountType !== null }>
                    <Spinner/>
                </div>

                {/* No account */}

                <div style={{ textAlign: "left", height:"36px", clear:"both" }} hidden={ accountType !== null }>
                    <img
                        alt="payment method icon"
                        src={debitCard1x}
                        srcSet={debitCard1x+' 1x,'+debitCard2x+' 2x,'+debitCard3x+' 3x'}
                        style={{ width:"32px", float:"left", marginRight:"10px", opacity: 0.35, filter: 'saturate(0%)' }}
                    />
                    <div style={{ float: "left" }}>
                        <p style={{ fontSize: "17px", margin:"0px 0px 4px 0px", color:"#333b4450" }}>
                            No account linked yet
                        </p>
                    </div>
                </div>

                {/* Debit cards */}

                <div style={{ textAlign: "left", height:"36px", clear:"both" }} hidden={ accountType !== 'card' }>
                    <img
                        alt="payment method icon"
                        src={debitCard1x}
                        srcSet={debitCard1x+' 1x,'+debitCard2x+' 2x,'+debitCard3x+' 3x'}
                        style={{ width:"32px", float:"left", marginRight:"10px" }}
                    />
                    <div style={{ float: "left" }}>
                        <p style={{ fontSize: "17px", margin:"0px 0px 4px 0px", color:"#333b44" }}>
                            Debit Card
                        </p>
                    </div>
                </div>

                {/* Bank account */}

                <div style={{ textAlign: "left", height:"36px", clear:"both" }} hidden={ accountType !== 'bank' }>
                    <img
                        alt="payment method icon"
                        src={bankAccount1x}
                        srcSet={bankAccount1x+' 1x,'+bankAccount2x+' 2x,'+bankAccount3x+' 3x'}
                        style={{ height:"29px", width:"32px", float:"left", marginRight:"10px" }}
                    />
                    <div style={{ float: "left" }}>
                        <p style={{ fontSize: "17px", margin:"3px 0px 4px 0px", color:"#333b44" }}>
                            Bank Account
                        </p>
                    </div>
                </div>

                {/* Paypal */}

                <div style={{ textAlign: "left", height:"36px", clear:"both" }} hidden={ accountType !== 'paypal' }>
                    <img
                        alt="payment method icon"
                        src={debitCard1x}
                        srcSet={paypal1x+' 1x,'+paypal2x+' 2x,'+paypal3x+' 3x'}
                        style={{ width:"42px", float:"left", marginRight:"10px" }}
                    />
                    <div style={{ float: "left" }}>
                        <p style={{ fontSize: "17px", margin:"5px 0px 4px 0px", color:"#333b44" }}>
                            PayPal
                        </p>
                    </div>
                </div>

                <div style={{ height:"22px" }}></div>

                <button type="button" className="btn btn-secondary btn-block" onClick={ () => routerContext.history.push("/payment/ChoosePaymentMethod") }>
                    Change Connected Account
                </button>

            </CardView>
        </div>
    )
}

export default ConnectedAccountsPage