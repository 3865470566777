import React, { useRef, useState, useEffect } from 'react'
import { Client, HostedFields, dataCollector } from 'braintree-web'
import PaymentManager from './PaymentManager'

import Spinner from '../../components/Spinner/Spinner'
import CardView from '../../components/CardView/CardView'
import IconedTitle from '../../components/IconedTitle/IconedTitle'

import debitCard1x from '../../assets/images/image1x/debit_card.png'
import debitCard2x from '../../assets/images/image2x/debit_card.png'
import debitCard3x from '../../assets/images/image3x/debit_card.png'

import card1x from '../../assets/icon/icon-card.png'
import card2x from '../../assets/icon/icon-card@2x.png'
import card3x from '../../assets/icon/icon-card@3x.png'

import visa1x from '../../assets/icon/icon-visa.png'
import visa2x from '../../assets/icon/icon-visa@2x.png'
import visa3x from '../../assets/icon/icon-visa@3x.png'

import mastercard1x from '../../assets/icon/icon-mastercard.png'
import mastercard2x from '../../assets/icon/icon-mastercard@2x.png'
import mastercard3x from '../../assets/icon/icon-mastercard@3x.png'
import { withRouter } from 'react-router'

import '../../components/Page/Page.css'
import './AddDebitCardPage.css'

declare const braintree: any

const styles = {
    'input': {
        'font-family': 'Arial',
        'font-size': '16px',
        'color': '#3A3A3A'
    }
}

declare global {
    interface Window {
        flags?: {
            paypal?: boolean
        }
    }
}

const fields = {
    number: {
        selector: '#card-number',
        placeholder: 'XXXX XXXX XXXX XXXX'
    },
    expirationDate: {
        selector: '#card-expiry',
        placeholder: 'MM / YY'
    },
    cvv: {
        selector: '#card-cvv',
        placeholder: 'CVV'
    }
}

const cardIconSrcFor = (cardType: string | null, res: number): string => {
switch (cardType) {
    case 'visa':
        switch (res) {
            case 3: return visa3x
            case 2: return visa2x
            default: return visa1x
        }
        case 'master-card':
        switch (res) {
            case 3: return mastercard3x
            case 2: return mastercard2x
            default: return mastercard1x
        }
        default:
        switch (res) {
            case 3: return card3x
            case 2: return card2x
            default: return card1x
        }
    }
}

const getCardImageSrc = (cardLogo: string | null): { src: string, srcSet: string} => {
    return {
        src: `${ cardIconSrcFor(cardLogo, 1) }`,
        srcSet: `${ cardIconSrcFor(cardLogo, 1) } 1x, ${ cardIconSrcFor(cardLogo, 2) } 2x, ${ cardIconSrcFor(cardLogo, 3) } 3x`
    }
}

const useClient = (): Client | null => {
    const [client, setClient] = useState(null as Client | null)
    useEffect(() => {
        PaymentManager
            .getInstance()
            .getToken()
            .then(authorization => braintree.client.create({ authorization }))
            .then(client => setClient(client))
    }, [])
    return client
}

export default withRouter(({ history }) => {

    const paypalOn = window.flags && window.flags.paypal === true

    const [cardType, setCardType] = useState(null as string | null)

    const [isLoading, setIsLoading] = useState(false)
    const [onReady, setOnReady] = useState(undefined as boolean | undefined)
    const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null as HostedFields | null)

    const [cardholderName, setCardholderName] = useState('')
    const [namePristine, setNamePristine] = useState(true)
    const [nameEmpty, setNameEmpty] = useState(true)
    const [nameValid, setNameValid] = useState(undefined as boolean | undefined)

    const numberRef = useRef<HTMLDivElement>(null)
    const [numberPristine, setNumberPristine] = useState(true)
    const [numberEmpty, setNumberEmpty] = useState(true)
    const [numberValid, setNumberValid] = useState(undefined as boolean | undefined)

    const expiryRef = useRef<HTMLDivElement>(null)
    const [expiryPristine, setExpiryPristine] = useState(true)
    const [expiryEmpty, setExpiryEmpty] = useState(true)
    const [expiryValid, setExpiryValid] = useState(false)

    const cvvRef = useRef<HTMLDivElement>(null)
    const [cvvPristine, setCvvPristine] = useState(true)
    const [cvvEmpty, setCvvEmpty] = useState(true)
    const [cvvValid, setCvvValid] = useState(false)


    const client = useClient()
    const [deviceData, setDeviceData] = useState(null as null | string)
    const [error, setError] = useState(null as string | null)

    useEffect(() => {
        if (numberRef.current === null || expiryRef.current === null || cvvRef.current === null) {
            return
        }
        setOnReady(true)
    }, [numberRef, expiryRef, cvvRef])

    // Validation for the Cardholder Name field
    const validateCardholderName = (text: string) => {
        setCardholderName(text)
        if (namePristine) {
            setNamePristine(false)
        }
        // Check if string is empty or only contains whitespace
        // (ie. spaces, tabs or line breaks)
        if (text == '' || !text.replace(/\s/g, '').length) {
            setNameEmpty(true)
        }
        else {
            setNameEmpty(false)
        }
        // Ensure string is only letters and basic symbols with 26 char max
        if (/^[a-zA-Z\\.;,:'"!@#$%^&*\-\(\) ]{1,26}$/.test(text)) {
            setNameValid(true)
        }
        else {
            setNameValid(false)
        }
    }

    // HOSTED FIELDS

    useEffect(() => {
        if (!onReady) {
            return
        }
        if (client === null) {
            return
        }
        var teardown = () => {}

        braintree.hostedFields
            .create({ client, styles, fields })
            .then((hostedFieldsInstance: any) => {
                setHostedFieldsInstance(hostedFieldsInstance)
                teardown = hostedFieldsInstance.teardown
                hostedFieldsInstance.on('cardTypeChange', () => {
                    setNumberPristine(false)
                })
                hostedFieldsInstance.on('cardTypeChange', (event: any) => {
                    if (event.emittedBy !== 'number') {
                        return
                    }
                    if (event.cards && event.cards.length > 0) {
                        setCardType(event.cards[0].type)
                    } else {
                        setCardType(null)
                    }
                })
                hostedFieldsInstance.on('empty', (event: any) => {
                    const field = event.fields[event.emittedBy]
                    setTimeout(() => {
                        switch (event.emittedBy) {
                        case 'number':
                            setCardType(null)
                            setNumberEmpty(true)
                            break
                        case 'expirationDate':
                            setExpiryEmpty(true)
                            break
                        case 'cvv':
                            setCvvEmpty(true)
                            break
                        }
                    }, 0)
                })
                hostedFieldsInstance.on('notEmpty', (event: any) => {
                    const notEmpty = !event.fields[event.emittedBy].isEmpty
                    switch (event.emittedBy) {
                    case 'number':
                        setNumberPristine(false)
                        setNumberEmpty(false)
                        break
                    case 'expirationDate':
                        setExpiryPristine(false)
                        setExpiryEmpty(false)
                        break
                    case 'cvv':
                        setCvvPristine(false)
                        setCvvEmpty(false)
                        break
                    }
                })
                hostedFieldsInstance.on('validityChange', (event: any) => {
                    const field = event.fields[event.emittedBy]
                    switch (event.emittedBy) {
                    case 'number':
                        setNumberValid((field.isValid) || (field.isPotentiallyValid ? undefined : false))
                        break
                    case 'expirationDate':
                        setExpiryValid(field.isValid)
                        break
                    case 'cvv':
                        setCvvValid(field.isValid)
                        break
                    }
                })
            })
        // return () => teardown()
    }, [onReady, client])

    function saveDebitCard() {
        if (!hostedFieldsInstance) {
            console.error('Attempted to send request before hosted fields instance was set. Aborting.')
            return
        }
        setNumberPristine(false)
        setExpiryPristine(false)
        setCvvPristine(false)
        setNamePristine(false)
        if (!numberValid || !expiryValid || !cvvValid || nameEmpty || !nameValid) {
            return
        }
        setIsLoading(true)
        hostedFieldsInstance
            .tokenize(
                {cardholderName: cardholderName}
            )
            .then((payload: { nonce?: string }) => {
                if (!payload || !payload.nonce) {
                    throw new Error()
                }
                return payload.nonce
            })
            .then((nonce: string) => PaymentManager.getInstance().SendRequest(nonce))
            .then(() => {
                setIsLoading(false)
                history.replace(PaymentManager.getInstance().getSuccessUrl())
            })
            .catch(({statusCode, message}) => {
                setIsLoading(false)
                switch (statusCode) {
                case 400:
                    window.alert(message)
                    break
                default:
                    history.push('/payment/error')
                break
                }
            })
    }

    // DEVICE DATA

    useEffect(() => {

        if (!paypalOn) {
            return
        }

        if (!client) {
            return
        }

        var teardDown = () => {}

        const timeout = setTimeout(function () {
            console.log('Getting device data timed out')
            setDeviceData('fake_device_data')
        }, 3000)

        dataCollector.create({
            client: client,
            paypal: true,
            kount: true
        }).then(dataCollectorInstance => {
            clearTimeout(timeout)
            setDeviceData(dataCollectorInstance.deviceData)
            teardDown = () => dataCollectorInstance.teardown()
        }).catch(error => {
            console.log('Could not get device data', error)
        })

        return () => {
            clearTimeout(timeout)
            teardDown()
        }

    }, [client])
    // TEMPLATE

    const hostedFieldsReady = !!hostedFieldsInstance

    return (
        <div>

            <p style={{color: 'red', fontWeight: 'bold'}}>{error}</p>

            {(hostedFieldsReady !== true) ? (
                <div style={{ position: 'absolute', zIndex: 99999, top: '0px', left: '0px', right: '0px', bottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner />
                </div>
            ) : (null)}

            <div className="Page" id="AddDebitCardPage" style={{textAlign: 'left'}}>
                <CardView>
                    <IconedTitle
                        title="Connect your debit card"
                        discription="Credit Cards will not be accepted."
                        logoSrc={debitCard1x}
                        logoSrcSet={`${debitCard1x} 1x, ${debitCard2x} 2x, ${debitCard3x} 3x`}
                        withLock={true}
                    />
                    <div id="debit-card">
                        <form action="" className="needs-validation" noValidate={false}>
                            <div className="form-group row mt-3">
                                <div className="col-12">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                            <img className="debit-card-logo"
                                                src={ getCardImageSrc(cardType).src } alt=""
                                                srcSet={ getCardImageSrc(cardType).srcSet }
                                            />
                                            </span>
                                        </div>
                                        <div id="card-number" className="form-control" ref={numberRef}></div>
                                    </div>
                                    <div hidden={numberPristine || numberEmpty || numberValid === true} className="field-error-message">Invalid card number</div>
                                    <div hidden={numberPristine || !numberEmpty} className="field-error-message">Card number required</div>
                                </div>
                            </div>
                                <div className="form-group row mt-3">
                                <div className="col-6">
                                    <div id="card-expiry" className="form-control" ref={expiryRef}></div>
                                    <div hidden={expiryPristine || expiryEmpty || expiryValid} className="field-error-message">Invalid card expiry..</div>
                                    <div hidden={expiryPristine || !expiryEmpty} className="field-error-message">Card expiry required</div>
                                </div>
                                <div className="col-6" ref={cvvRef}>
                                    <div id="card-cvv" className="form-control" ref={cvvRef}></div>
                                    <div hidden={cvvPristine || cvvEmpty || cvvValid} className="field-error-message">Invalid cvv</div>
                                    <div hidden={cvvPristine || !cvvEmpty} className="field-error-message">CVV required</div>
                                </div>
                            </div>
                            <div className="form-group row mt-3">
                                <div className="col-12">
                                    <div className="input-group" style={{opacity: hostedFieldsInstance ? 1 : 0.5}}>
                                        <input className="form-control cardholder-name" id="cardholder-name" placeholder="NAME ON CARD" value={cardholderName} onChange={(event) => {validateCardholderName(event.target.value)}} style={{outline: 'none', font: '400 16px', fontFamily: 'Arial', color: 'rgb(58, 58, 58)'}}></input>
                                    </div>
                                    <div hidden={namePristine || nameEmpty || nameValid === true} className="field-error-message">Name must be letters and basic symbols, with a 26 character maximum</div>
                                    <div hidden={namePristine || !nameEmpty} className="field-error-message">Name on Card required</div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-lg btn-block"
                                        onClick={() => saveDebitCard()}
                                        style={{opacity: hostedFieldsReady && numberValid && expiryValid && cvvValid ? 1 : 0.7}}>
                                        { !isLoading ? 'Connect My Debit Card' : 'Saving...' }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </CardView>
                <div style={{height: '40px'}}></div>
            </div>
        </div>
    )
})
