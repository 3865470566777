import * as SentryLogger from "@sentry/core"
import * as Sentry from "@sentry/types"
import { printDebug, printError, printWarning } from "../common/postMessage"
import { filterProps } from "../pages/utils"

// LOG

type ErrorMessageLength = 'short' | 'full'

const makeErrorMessageFrom = (item: any, length: ErrorMessageLength): string => {

    switch (true) {

        // Undefined error
        case !item:
            return 'Undefined error'

        // Pure string
        case typeof item === 'string':
            return `${item}`

        // Pure error
        case item instanceof Error && !!item.stack && length === 'full':
            return `Error: "${item.message}": ${item.stack.replace(/\s*\n\s*/g, ' ↩ ')}`
        case item instanceof Error:
            return `Error: "${item.message}"`

        // Errors usually generated by promises 
        case item.hasOwnProperty('errorCode') && item.hasOwnProperty('message'):
            return `Error(${item.errorCode}): "${item.message}" ${filterProps(item, ['message', 'errorCode'])}`
        case item.hasOwnProperty('errorCode'):
            return `Error(${item.errorCode}) ${filterProps(item, ['message', 'errorCode'])}`
        case item.hasOwnProperty('message'):
            return `Error: "${item.message}" ${filterProps(item, ['message', 'errorCode'])}`

        // Any other object with custom props or array
        default:
            return `${JSON.stringify(item)}`
    }
}

export const logCriticicalError = (error: any) => {
    SentryLogger.captureMessage(makeErrorMessageFrom(error, 'short'), Sentry.Severity.Critical)
    printError(makeErrorMessageFrom(error, 'full'))
}

export const logError = (error: any) => {
    SentryLogger.captureMessage(makeErrorMessageFrom(error, 'short'), Sentry.Severity.Error)
    printError(makeErrorMessageFrom(error, 'full'))
}

export const logWarning = (error: any) => {
    SentryLogger.captureMessage(makeErrorMessageFrom(error, 'short'), Sentry.Severity.Warning)
    printWarning(makeErrorMessageFrom(error, 'full'))
}

export const logInfo = (error: any) => {
    SentryLogger.captureMessage(makeErrorMessageFrom(error, 'short'), Sentry.Severity.Info)
    printDebug(makeErrorMessageFrom(error, 'full'))
}