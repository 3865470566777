import React from 'react'
import CardView from '../../components/CardView/CardView'
import AccountListItem from '../../components/AccountListItem/AccountListItem'
import './SelectAccount.scss'

import stc2x from '../../image2x/stcIcon.png'
import stc3x from '../../image3x/stcIcon.png'
import { useStoreActions, Actions } from 'easy-peasy'
import { useAccounts } from './hooks'
import Spinner from '../../components/Spinner/Spinner'
import { StoreModel } from '../../common/contexts/model/Index'
import { __RouterContext } from 'react-router'
import useTitle from '../../common/useTitle'
import { DonationSourceAccount } from './api'
import { TITLE_MAKE_A_DONATION } from './Constants'

interface Props {
    action?: () => void
}

const SelectAccount: React.FC<Props> = ({action}) => {

    const setSelectedDonationSource = useStoreActions((actions: Actions<StoreModel>) => actions.Donation.setSourceAccount)
    const [loadingAccounts, accountsError, accounts] = useAccounts()

    useTitle(TITLE_MAKE_A_DONATION)

    const handleSelectAccount = (account:DonationSourceAccount) => {
        setSelectedDonationSource(account)
        action && action()
    }

    return (
        <div>
            <CardView>
                
                <div style={{textAlign: 'left'}}>
                    <div className="text" style={{marginTop: "8px", marginBottom: "24px"}}>
                        SELECT FROM ACCOUNT...
                    </div>
                    {(accountsError) ? (
                        <p style={{color: 'red'}}>
                            {accountsError.message}
                        </p>
                    ) : (loadingAccounts) ? (
                        <div style={{margin: '15px 0px 25px'}}>
                            <Spinner/>
                        </div>
                    ) : (accounts) ? (accounts.map(account => 
                        <AccountListItem key={account.id} account={account} filled={false} onClick={() => {
                            handleSelectAccount(account)
                        }}/>)
                    ) : (null)}
                </div>

                <div style={{height: "1px", backgroundColor: "#e6e6e6", marginBottom: "24px"}}></div>

                <div className="text" style={{marginBottom: "24px"}}>
                    TO:
                </div>

                <div className="d-flex align-items-center" style={{marginBottom: "8px"}}>
                    <div style={{marginRight: "8px"}}>
                        <img srcSet={`${stc2x} 2x, ${stc3x} 3x`} alt="Save the Children" />
                    </div>
                    <div className="text">
                        Save the Children
                    </div>
                </div>

            </CardView>
        </div>
    )
}

export default SelectAccount