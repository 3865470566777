import * as humps from 'humps'
import axios, { AxiosInstance, AxiosTransformer } from 'axios'
import getBaseURL from '../pages/utils'
import { getAuthToken } from '../common/authToken'
import { isArray } from 'util'

export class ClientApi {

    private static instance: ClientApi
    private networkInstance: Promise<AxiosInstance>

    protected get http(): Promise<AxiosInstance> {
        return this.networkInstance
    }

    constructor() {

        const [tokenPromise] = getAuthToken()

        this.networkInstance = tokenPromise
            .then((authToken) => axios.create({
                baseURL: getBaseURL(window.location.hostname),
                timeout: 30000,
                headers: { 'Authorization': authToken },
                transformResponse: (function (defaults, camelizeTransformer) {
                    if (!defaults) {
                        return [camelizeTransformer]
                    }
                    if (!isArray(defaults)) {
                        return [defaults, camelizeTransformer]
                    }
                    return [...defaults, camelizeTransformer]
                })(axios.defaults.transformResponse, <AxiosTransformer>(data => humps.camelizeKeys(data))),
                transformRequest: ((defaults, camelizeTransformer) => {
                    if (!defaults) {
                        return [camelizeTransformer]
                    }
                    if (!isArray(defaults)) {
                        return [camelizeTransformer, defaults]
                    }
                    return [camelizeTransformer, ...defaults]
                })(axios.defaults.transformRequest, <AxiosTransformer>(data => humps.decamelizeKeys(data)))
            }))
            .then((axios) => axios)
    }

    /**
     * When subclassing, override this method to return an instance
     * of the type of the subclass.
     * 
     * @example
     * class SomeClientApi extends ClientApi {
     *   static getInstance(): SomeClientApi {
     *     return <SomeClientApi>(super.getInstance())
     *   }
     * }
     */
    static getInstance<T extends ClientApi>(): T {
        if (!this.instance) {
            this.instance = new this()
        }
        return <T>(this.instance)
    }
}