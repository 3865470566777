import React, { useState, useEffect } from 'react'
import CardView from '../../components/CardView/CardView'
import './SelectAccount.scss'

import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import FilledButton from '../../components/FilledButton/FilledButton'
import { useCreateDonation } from './hooks'
import { StandardError } from '../../common/error'
import { Redirect } from 'react-router'
import LoadingCardView from '../../components/LoadingCardView/LoadingCardView'
import { setBackButtonHidden, setTitle } from '../../common/postMessage'
import { StoreModel } from '../../common/contexts/model/Index'
import { TITLE_MAKE_A_DONATION, NAME_CHARITY_SAVE_THE_CHILDREN } from './Constants'

interface Props {
    action?: (success: boolean) => void,
}

const ConfirmDonation: React.FC<Props> = ({action}) => {
    
    const finishedDonation = (error: StandardError | null, firstDonation: boolean, amount: string) => {
        setDonationAmount(amount)
        setIsFirstDonation(firstDonation)
        action && action(error === null)
    }
    
    const donation = useStoreState(state => state.Donation)
    const amount = donation.amount
    
    const setDonationAmount = useStoreActions((actions: Actions<StoreModel>) => actions.Donation.setAmount)
    const setIsFirstDonation = useStoreActions((actions: Actions<StoreModel>) => actions.Donation.setFirstDonation)
    const selectedDonationSource = useStoreState(state => state.Donation)
    const accountId = !selectedDonationSource.sourceAccount ? -1 : selectedDonationSource.sourceAccount.id
    const [isLoading, donationTrigger] = useCreateDonation(NAME_CHARITY_SAVE_THE_CHILDREN, accountId, finishedDonation)

    useEffect(() => {
        setBackButtonHidden(isLoading)
        setTitle(isLoading ? '' : TITLE_MAKE_A_DONATION)
    }, [isLoading])

    const onDonateClicked = () => {
        donationTrigger.current && donationTrigger.current(amount)
    }

    const renderContent = () => {
        return (
            <div style={{ padding: "23px 0px" }}>
                <div className="conf_row">
                    <div className="conf_column">
                        <b >FROM:</b>
                    </div>
                    <div className="conf_column">
                        <p>{donation.sourceAccount.name}</p>
                    </div>
                </div>
                <div className="conf_row">
                    <div className="conf_column">
                        <b >TO:</b>
                    </div>
                    <div className="conf_column">
                        <p>Save The Children</p>
                    </div>
                </div>
                <div className="conf_row">
                    <div className="conf_column">
                        <b >AMOUNT:</b>
                    </div>
                    <div className="conf_column">
                        <p>${amount}</p>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div>
            {(!selectedDonationSource.sourceAccount) ? (
                <Redirect to="/charity/error"/>                
            ) : (isLoading) ? (
                <LoadingCardView title="Processing your donation..."/>
            ) : (
                <div style={{height: "100%",display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <CardView style={{ padding: "16px", paddingTop: "24px" }}>
                        <p style={{ fontSize: "18px" }} className="Title">Please confirm donation details</p>
                        <div>
                            {renderContent()}
                        </div>
                        <div>
                            <FilledButton
                                title="Confirm Donation"
                                bgcolor="#1c4270"
                                action={onDonateClicked}
                            />
                        </div>
                    </CardView>
                </div>
            )}
        </div>
    )
}

export default ConfirmDonation