import React, { useEffect, useState } from 'react'
import FilledButton from '../../../components/FilledButton/FilledButton'
import * as CSS from 'csstype'
import logo from '../../../assets/icon/greentick.svg'

import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import icInfotick1x from '../../../assets/images/image1x/icInfotick.png'
import icInfotick2x from '../../../assets/images/image2x/icInfotick.png'
import icInfotick3x from '../../../assets/images/image3x/icInfotick.png'

import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'
import useTitle from '../../../common/useTitle'
import { showCancelButton } from '../../../common/postMessage'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: "8px",

    },
    paper: {
        margin: "16px",
        padding: "15px",
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))


const headerStyle: CSS.Properties = {
    marginTop: '12px'
}


const normalStyle: CSS.Properties = {
    fontSize: '17px',
    letterSpacing: '0.36px',
    textAlign: 'left',
    color: '#333b44'
}

const pointStyle: CSS.Properties = {
    fontSize: '17px',
    letterSpacing: '0.36px',
    textAlign: 'left',
    color: '#333b44',
    marginLeft: '13px'
}

export interface Props {
    action?: () => void
    actionReset?: () => void
}

const ChildAddAnotherChild: React.FC<Props> = ({ action, actionReset: actionRest }, ...rest) => {

    const classes = useStyles(rest)

    const storeGetChild = useStoreState(state => state.Child)
    const { firstName } = storeGetChild

    useTitle('Sign Up Spriggy')

    //action
    const gotoNextPage = () => {
        return action ? action() : null
    }
    const addAnotherChild = () => {
        return actionRest ? actionRest() : null
    }

    var [shouldDisplayTick, setShouldDisplayTick] = useState(false)

    useEffect(() => {
        const token = setTimeout(() => {
            setShouldDisplayTick(true)
        }, 800)
        return () => {
            clearTimeout(token)
        }
    }, [])

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img
                            className={shouldDisplayTick ? 'tick-large tick-large--on' : 'tick-large'}
                            src={logo}
                            alt="Green Tick"
                            width={100}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <p className="TitleLabel" style={headerStyle}>
                            You've added {firstName} to Spriggy!
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <p style={normalStyle}>Your child gets:</p>
                            </Grid>
                            <Grid item xs={12}>

                                <Grid container wrap="nowrap" direction="row">
                                    <Grid item>
                                        <img
                                            src={icInfotick1x}
                                            srcSet={icInfotick1x + ' 1x,' + icInfotick2x + ' 2x,' + icInfotick3x + ' 3x'}
                                            alt="Green Tick"
                                        />
                                    </Grid>
                                    <Grid item><p style={pointStyle}>A 30-day free trial of Spriggy and all it’s features</p></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container wrap="nowrap">
                                    <Grid item>
                                        <img
                                            src={icInfotick1x}
                                            srcSet={icInfotick1x + ' 1x,' + icInfotick2x + ' 2x,' + icInfotick3x + ' 3x'}
                                            alt="Green Tick"
                                        />
                                    </Grid>
                                    <Grid item><p style={pointStyle}>Their awesome pre-paid card, which will arrive in 7-10 working days</p></Grid>
                                </Grid>
                            </Grid></Grid>

                    </Grid>
                    <Grid item xs={12}>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <FilledButton title="Add Another Child" action={() => addAnotherChild()} />
                    </Grid>
                    <Grid item xs={12}>
                        <FilledButton
                            title="Done"
                            bgcolor="rgba(30, 163, 206, 0.15)"
                            color="#1ea3ce"
                            action={gotoNextPage}
                        />
                    </Grid>
                </Grid>

            </Paper>
        </React.Fragment>
    )
}

export default ChildAddAnotherChild