import React, { FC, HTMLAttributes, useState, useEffect } from "react";
import CardView from "../../../components/CardView/CardView";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../common/contexts/model/Index";
import { AustralianState } from "../../../common/contexts/model/Parent";
import InputSelect from "../../../components/InputSelect";
import { InputOption } from "../../../components/InputSelect/InputSelect";
import './EnterManualAddress.scss'
import useTitle from '../../../common/useTitle'

interface EnterManualAddressProps extends HTMLAttributes<HTMLDivElement> {
    action: () => void
}

export default ((({action, ...props}) => {

    useTitle('Sign Up Spriggy')

    const saveManualAddress = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setManualAddress)

    const statesOptions: InputOption<AustralianState>[] = [
        { value: 'ACT', label: 'Australian Capital Territory' },
        { value: 'NSW', label: 'New South Wales' },
        { value: 'NT', label: 'Northen Territory' },
        { value: 'QLD', label: 'Queensland' },
        { value: 'VIC', label: 'Victoria' },
        { value: 'WA', label: 'Western Australia' }
    ]

    const [street, setStreet] = useState('')
    const [suburb, setSuburb] = useState('')
    const [postcode, setPostcode] = useState('')
    const [state, setState] = useState(null as null | InputOption<AustralianState>)

    const [valid, setIsValid] = useState(false)

    useEffect(() => {

        setIsValid(true
            && street.length > 5
            && suburb.length > 2
            && /^\d{4}$/.test(postcode)
            && state !== null
            && !!state.label
            && statesOptions.some(o => state.value === o.value)
        )

    }, [street, suburb, postcode, state, statesOptions])

    const onNext = () => {

        if (state === null) {
            return
        }

        saveManualAddress({
            address_line1: street,
            city: suburb,
            state: state.value,
            postcode: postcode
        })

        setTimeout(() => action(), 200)
    }

    return (
        <div className="EnterManualAddress" {...props}>
            <CardView>
                <p className="TitleLabel">
                    What is your address?
                </p>
                <div className="EnterManualAddress-line">
                    <input type="text" className="PlainInput" value={street} onChange={e=>setStreet(e.currentTarget.value)} placeholder="Address"/>
                </div>
                <div className="EnterManualAddress-line">
                    <input type="text" className="PlainInput" value={suburb} onChange={e=>setSuburb(e.currentTarget.value)} placeholder="Suburb"/>
                </div>
                <div className="EnterManualAddress-line">
                    <input type="number" pattern="\d*" className="PlainInput" value={postcode} onChange={e=>setPostcode(e.currentTarget.value)} placeholder="Postcode"/>
                    <InputSelect placeholder="State" options={statesOptions} onOptionSelected={setState}/>
                </div>
                <FilledButton title="Next" action={() => onNext()} invalid={!valid} />
            </CardView>
        </div>
    )

}) as FC<EnterManualAddressProps>)