import React, { useEffect } from 'react'

import * as CSS from 'csstype'
import CardView from '../../components/CardView/CardView'
import '../../components/Page/Page.css'
import { Link } from 'react-router-dom'

import useTitle from '../../common/useTitle'
import { postAnalytics, postDismiss, setBackButtonHidden } from '../../common/postMessage'

import {ReactComponent as GreenTick} from '../../assets/icon/greentick.svg'


const TopUpSuccess: React.FC = () => {

    useEffect(() => {
        setBackButtonHidden(true)
        postAnalytics("Guided Onboarding - Top Up Submitted")
    }, [])

    return (
        <div className="Page" id="TopUpSuccess">

            <CardView>

                <div style={{ paddingTop: '10px'}}>
                    <GreenTick style={{ marginBottom: '20px' }} />
                    <h1 style={{ fontSize: '22px', fontFamily: 'Bariol-Bold', marginBottom: '30px' }}>
                        Your Top Up is on its way!
                    </h1>
                    <p style={{ marginBottom: '20px' }}>
                        It will be in your Parent Wallet within 10 minutes if you connected a debit card or 3 business days if it's from your bank.
                    </p>
                    <p style={{ marginBottom: '30px' }}>
                        While you wait for your cards to arrive, let's explore Spriggy.
                    </p>
                    <p>
                        <button onClick={ () => { postDismiss() } } className="btn btn-primary btn-lg btn-block">
                            Let's jump right in
                        </button>
                    </p>
                </div>

            </CardView>
        </div>
    )
}

export default TopUpSuccess
