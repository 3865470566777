import React, { useEffect } from 'react';
import donateSuccess1x from '../../assets/images/image1x/donate_success.png';
import donateSuccess2x from '../../assets/images/image2x/donate_success.png';
import donateSuccess3x from '../../assets/images/image3x/donate_success.png';
import CardView from '../../components/CardView/CardView';
import SaveTheChildrenLink from './SaveTheChildrenLink';
import { setBackButtonHidden, setTitle } from '../../common/postMessage'
import { postDismiss } from '../../common/postMessage'

import { useStoreState } from 'easy-peasy'
import useTitle from '../../common/useTitle';
import useUser from '../../common/useUser';
import { flexbox } from '@material-ui/system';

interface Props {
}

const DonateSuccess: React.FC<Props> = (props: Props, ...rest) => {

    const donation = useStoreState(state => state.Donation)
    const user = useUser()

    useTitle('')

    useEffect(() => {
        setBackButtonHidden(true)
    }, [])

    const renderUserName = () => {
        if (user && user.firstName) {
            return user.firstName
        }
        return ""
    }

    const renderTitle = () => {
        return "You are amazing!"
    }

    const renderContent = () => {
        return (
            <div>
                <p>Thank you {renderUserName()}! Your ${donation.amount} is helping to create a brighter future for Aussie kids.</p>
                <p style={{marginBottom: 24}}>Your giving journey doesn't have to stop here. You can give at any time from the Savings and Goals screen to help us reach our goal of $100,000 for Save the Children by February 2020.</p>
            </div>
        )
    }

    const onDoneClicked = () => {
        postDismiss();
    };

    return (
        <div style={{height: "100%",display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <CardView style={{ padding: "16px", paddingTop: "24px" }}>
                <img 
                    src={donateSuccess1x}
                    srcSet={donateSuccess1x+ ' 1x,' + donateSuccess2x+ ' 2x,' + donateSuccess3x+ ' 3x'}
                />
                <p className="Title">
                    {renderTitle()}
                </p>
                <div>
                    {renderContent()}
                </div>
                <button type="button" className="btn btn-positive btn-block"
                    onClick={onDoneClicked}>
                    Got it
                </button>
            </CardView>
            <div>

                <p style={{ verticalAlign: "middle", textAlign:"center", marginBottom: 0}}>
                    For more information on Save the Children
                </p>
                <p style={{ verticalAlign: "middle", textAlign:"center"}}>
                    {SaveTheChildrenLink("https://www.savethechildren.org.au", "www.savethechildren.org.au")}
                </p>
            </div>
        </div>
    )
}

export default DonateSuccess;