import React from 'react'
import * as CSS from 'csstype'
import './SetupBox.css'
import Grid from '@material-ui/core/Grid'

export interface Props {
    header: string,
    subText: string
    isCompleted?: boolean
}

const boxHeader: CSS.Properties = {
    fontSize: "18px",
    fontWeight: "bold"
}
const boxText: CSS.Properties = {
    fontSize: '13px'
}

const SetupBox: React.FC<Props> = ({ header, subText, isCompleted = false }: Props) => {
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className="box">
                        <Grid item xs={2} className="boxLeft">
                            {isCompleted ? <div className="dot" />
                                : <div className="dotWhite" />
                            }
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container className="boxRight">
                                <Grid item xs={12}><div style={boxHeader}>{header}</div></Grid>
                                <Grid item xs={12}><div style={boxText}>{subText}</div></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SetupBox