import React, { useEffect } from 'react'

import * as CSS from 'csstype'
import CardView from '../../components/CardView/CardView'
import '../../components/Page/Page.css'
import { Link } from 'react-router-dom'

import useTitle from '../../common/useTitle'
import { postAnalytics, postDismiss, setBackButtonHidden, showContactSupport } from '../../common/postMessage'

import {ReactComponent as RedCross} from '../../assets/icon/redcross.svg'


const TopUpFailure: React.FC = () => {

    useTitle('')

    useEffect(() => {
        setBackButtonHidden(true)
        postAnalytics("Guided Onboarding - Top Up Error")
    }, [])

    return (
        <div className="Page" id="TopUpError">

            <CardView>

                <div style={{ paddingTop: '10px'}}>
                    <RedCross style={{ marginBottom: '20px' }} />
                    <h1 style={{ fontSize: '22px', fontFamily: 'Bariol-Bold', marginBottom: '30px' }}>
                        Oops! Something went wrong
                    </h1>
                    <p style={{ marginBottom: '20px' }}>
                        We're unable to top up right now, but you can still use the rest of Spriggy.
                    </p>
                    <p style={{ marginBottom: '30px' }}>
                        Please contact support so we can get you up and running ASAP.
                    </p>
                    <p>
                        <button onClick={ () => { showContactSupport() } } className="btn btn-primary btn-lg btn-block">
                            Contact Support
                        </button>
                        <button onClick={ () => { postDismiss() } } style={{fontSize: '18px', fontFamily: 'Bariol'}} className="btn btn-link btn-lg btn-block">
                            Explore Spriggy
                        </button>
                    </p>
                </div>

            </CardView>
        </div>
    )
}

export default TopUpFailure
