import React, { useState, useEffect } from 'react'
import CardView from '../../components/CardView/CardView'
import AccountListItem from '../../components/AccountListItem/AccountListItem'
import './SelectAccount.scss'

import stc2x from '../../image2x/stcIcon.png'
import stc3x from '../../image3x/stcIcon.png'
import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import FilledButton from '../../components/FilledButton/FilledButton'
import { Redirect } from 'react-router'
import { StoreModel } from '../../common/contexts/model/Index'

interface Props {
    action?: () => void
}

const SelectAmount: React.FC<Props> = ({action}) => {
    
    const finishedDonation = (amount: string) => {
        setAmount(amount)
        setDonationAmount(amount)
        action && action()
    }

    const [amount, setAmount] = useState("5")
    const [textFocussed, setTextFocussed] = useState(false)
    const donation = useStoreState(state => state.Donation)
    const setDonationAmount = useStoreActions((actions: Actions<StoreModel>) => actions.Donation.setAmount)
    const selectedDonationSource = useStoreState(state => state.Donation)

    const isAmountInvalid = () => {
        const numberAmount = parseFloat(amount)
        return (isNaN(numberAmount) 
            || amount === '' 
            || numberAmount === 0 
            || numberAmount > parseFloat(selectedDonationSource.sourceAccount.balance))
    }

    const validateSetInput = (text: string) => {
        const textNumberCharacersOnly = text.replace(/[^\d.]/g,'').slice(0, 8);
        const firstDecimalIndex = textNumberCharacersOnly.indexOf('.')
        const lastDecimalIndex = textNumberCharacersOnly.lastIndexOf('.')
        // No decimals, return string as is
        if (firstDecimalIndex === -1) {
            setAmount(textNumberCharacersOnly)
            return
        } else {
            // More than one decimal, remove
            if (firstDecimalIndex !== lastDecimalIndex) {
                const validatedText = textNumberCharacersOnly.slice(0, lastDecimalIndex) 
                                    + textNumberCharacersOnly.slice(lastDecimalIndex + 1)
                setAmount(validatedText)
                return
            } else {
                const validatedText = textNumberCharacersOnly.slice(0, firstDecimalIndex + 3)
                setAmount(validatedText) 
                return
            }

        }
    }

    return(
        <div>
            {(!selectedDonationSource.sourceAccount) ? (
                <Redirect to="/charity/error"/>                
            ) : (
                <CardView>
                    <div style={{textAlign: 'left'}}>
                        <div className="text" style={{marginTop: "8px", marginBottom: "24px"}}>
                            FROM:
                        </div>
                        <AccountListItem
                            account={selectedDonationSource.sourceAccount}
                            filled={true}
                        />
                    
                        <div style={{height: "1px", backgroundColor: "#e6e6e6", marginBottom: "24px"}}></div>
                        <div className="text" style={{marginBottom: "24px"}}>
                            TO:
                        </div>
                        <div className="d-flex align-items-center" style={{marginBottom: "24px"}}>
                            <div style={{marginRight: "8px"}}>
                                <img srcSet={`${stc2x} 2x, ${stc3x} 3x`} alt="Save the Children" />
                            </div>
                            <div className="text">
                                Save the Children
                            </div>
                        </div>
                        <div style={{height: "1px", backgroundColor: "#e6e6e6", marginBottom: "24px"}}></div>
                        <div className="text" style={{marginTop: "8px", marginBottom: "24px"}}>
                            SELECT AMOUNT...
                        </div>
                        <div style={{position: "relative", textAlign: "center", marginBottom: "24px", overflowX: "hidden"}}>
                            <div style={{position: "absolute", width:"100%", textAlign:"center", display: "block", color: "#333b44"}}>
                                <label style={{fontSize:"37px", fontWeight:"bold", zIndex:9}}>${amount}</label>
                                <label className="blink" style={{fontSize:"37px", fontWeight:"lighter", zIndex:9, color:textFocussed?'#333b44':'transparent'}}>|</label>
                            </div>

                            <input type="text" inputMode="decimal" value={amount} onChange={(event) => {validateSetInput(event.target.value)}} style={{position: "relative", width:"1000px", textAlign: "left", fontSize: "37px", backgroundColor: "transparent", color:"transparent", caretColor: "transparent", fontWeight: "bold", zIndex:10, borderWidth:"0px", left:"-200px"}} onFocus={event => setTextFocussed(true)} onBlur={event => setTextFocussed(false)}>
                            </input>
                        </div>
                        <div>
                            <FilledButton
                                title="Donate Now"
                                bgcolor="#1c4270"
                                action={ () => finishedDonation(amount)}
                                invalid={isAmountInvalid()}
                            />
                        </div>
                    </div> 
                </CardView>
            )}
        </div>
    )
}

export default SelectAmount