import { logInfo, logCriticicalError, logError } from "./log"

declare global {
    interface Window {
        INTERNAL: boolean
        onpasstoken: () => [Promise<string>, () => void]
    }
}

export const getAuthToken = (): [Promise<string>, () => void] => {
    return window.onpasstoken()
}
