import React, { useContext, useEffect } from 'react'
import { __RouterContext } from "react-router"

import * as CSS from 'csstype'
import CardView from '../../components/CardView/CardView'
import '../../components/Page/Page.css'
import { Link } from 'react-router-dom'

import useTitle from '../../common/useTitle'
import { postAnalytics } from '../../common/postMessage'

import PaymentManager from '../payment/PaymentManager'
import {ReactComponent as TopUp} from '../../assets/images/onboarding/top_up.svg'


const TopUpPrompt: React.FC = () => {

    useTitle('')

    const routerContext = useContext(__RouterContext)

    useEffect(() => {
        postAnalytics("Guided Onboarding - Top Up Prompt")
    }, [])

    const connectAccount = () => {
        PaymentManager.getInstance().setOnboardingStatus(true)
        routerContext.history.push('/payment/choosePaymentMethod')
    }

    return (
        <div className="Page" id="TopUpPrompt">

            <CardView>

                <div style={{ paddingTop: '10px' }}>
                    <TopUp style={{ marginBottom: '30px' }} />
                    <h1 style={{ fontSize: '22px', fontFamily: 'Bariol-Bold', marginBottom: '30px' }}>
                        Top up your Parent Wallet
                    </h1>
                    <p style={{ marginBottom: '10px' }}>
                        Pocket money isn’t much fun without money.
                    </p>
                    <p style={{ marginBottom: '30px' }}>
                        Send your kids money by connecting an account and making your first top up.
                    </p>
                    <p>
                        <button onClick={connectAccount} className="btn btn-primary btn-lg btn-block">
                            Sounds great, let's go!
                        </button>
                    </p>
                    <p>
                        <Link to="/onboarding/topup/dismiss">
                            <button style={{fontSize: '18px', fontFamily: 'Bariol'}} className="btn btn-link btn-lg btn-block">
                                I won't be giving money to my kids
                            </button>
                        </Link>
                    </p>
                </div>

            </CardView>
        </div>
    )
}

export default TopUpPrompt
