import PromiseRegistry from './promiseRegistry'

declare global {
    interface Window {
        Android: {
            postAnalytics: (paylod: string) => void
            dismiss: () => void
            saveAuthToken: (userId: number, token: string, passcode: string, userName: string) => void
            showCancelButton: () => void
            setTitle: (title: string) => void
            goToSavedLogin: () => void
            setBackButtonHidden: (isHidden: boolean) => void
            setLogoutButtonHidden: (isHidden: boolean) => void
            requestTopUp: (reference: string) => void
            showContactSupport: () => void
        }
        POST_MESSAGE_IN_LEGACY_MODE?: boolean
    }
}

type legacy___Handler = 'analyticsHandler' | 'mainHandler'

/**
 * DEPRECATED!!! Do NOT use for new call to native anymore. Use `post(action:payload?:)` instead.
 * @deprecated
 * @returns A boolean evaluating to false ONLY when legacy iOS handler could not be found.
 */
function legacy___post(handler: legacy___Handler, message: string): boolean {
    if (window.POST_MESSAGE_IN_LEGACY_MODE === false) { return false } try {
    (window as any).webkit.messageHandlers[handler].postMessage(message) }
    catch (err) { console.error(err) } try { if (window.Android) { switch (handler)
    { case 'analyticsHandler': window.Android.postAnalytics(message);
    break; case 'mainHandler': switch (message) { case 'dismiss': window.Android.dismiss();
    break; default: break } break; default: break} } } catch
    (err) { console.error(err) } return true
}


/**
 * @param action A string describing what the native env. needs to execute. Must start with a verb.
 * @param payload A string or an object containing infos the native env. should decode.
 */
function post(action: string, payload?: any) {

    let iOSJsonMessage: string | undefined
    if (payload !== undefined) {
        iOSJsonMessage = JSON.stringify({ action, payload: JSON.stringify(payload) })
    } else {
        iOSJsonMessage = JSON.stringify({ action })
    }
    try {
        (window as any).webkit.messageHandlers.nativeHandler.postMessage(iOSJsonMessage)
        return
    } catch (err) {
        console.warn && console.warn(`Could not find iOS message handler - aborting post of message "${action}" with payload "${payload}"`)
    }

    const androidJsonPayload = JSON.stringify(payload)
    try {
        switch (action) {
            case 'viewScreen':
                window.Android.postAnalytics(androidJsonPayload)
                break
            case 'dismiss':
                window.Android.dismiss()
                break
            case 'saveAuthToken':
                if (typeof payload === 'object') {
                    window.Android.saveAuthToken(payload.userId, payload.token, payload.passcode, payload.userName)
                }
                break
            case 'showCancelButton':
                window.Android.showCancelButton()
                break
            case 'setTitle':
                window.Android.setTitle(androidJsonPayload)
                break
            case 'goToSavedLogin':
                window.Android.goToSavedLogin()
                break
            case 'setBackButtonHidden':
                window.Android.setBackButtonHidden(payload)
                break
            case 'setLogoutButtonHidden':
                window.Android.setLogoutButtonHidden(payload)
                break
            case 'requestTopUp':
                window.Android.requestTopUp(payload)
                break
            case 'showContactSupport':
                window.Android.showContactSupport()
                break
            default:
                break
        }
        return
    } catch (err) {
        console.warn && console.warn(`Not find Android runtime either - aborting call of Android.${action}(${androidJsonPayload})`)
        console.warn(err)
    }
}

export function postAnalytics(analyticsId: string) {
    false
    || legacy___post('analyticsHandler', analyticsId)
    || post('viewScreen', analyticsId)
}

export function postDismiss() {
    false
    || legacy___post('mainHandler', 'dismiss')
    || post('dismiss')
}

export function saveAuthToken(userId: number, username: string, token: string, passcode: string) {
    post('saveAuthToken', { userId, token, passcode, username })
}

export function showCancelButton() {
    post('showCancelButton')
}

export function setTitle(title: string) {
    post('setTitle', title)
}

export function goToSavedLogin() {
    post('goToSavedLogin')
}

export function setBackButtonHidden(isHidden: boolean) {
    post('setBackButtonHidden', isHidden)
}

export function setLogoutButtonHidden(isHidden: boolean) {
    post('setLogoutButtonHidden', isHidden)
}

export function showContactSupport() {
    post('showContactSupport')
}

export function requestTopUp(): Promise<[boolean, boolean]> {
    console.log("Beginning request topup")
    return new Promise((res, rej) => {
        var reference = PromiseRegistry.getInstance().registerPromise('requestTopUp', res, rej)
        console.log("Got reference " + reference)
        post('requestTopUp', reference)
    })
}

// LOG

type LogLevel = 'debug' | 'warning' | 'error'

const log = (message: string, level: LogLevel) => {
    post('console', { message, level })
}

export function printDebug(message: string) {
    log(message, 'debug')
}

export function printWarning(message: string) {
    log(message, 'warning')
}

export function printError(message: string) {
    log(message, 'error')
}
