import React, { useEffect } from 'react'
import CardView from '../../components/CardView/CardView'
import * as CSS from 'csstype'
import '../../components/Page/Page.css'
import logo from '../../assets/icon/redcross.svg'
import { withRouter } from 'react-router'
import { postAnalytics, setBackButtonHidden } from '../../common/postMessage'
import useTitle from '../../common/useTitle'

const cardStyle: CSS.Properties = {
    padding: '24px'
}

const titleStyle: CSS.Properties = {
    marginTop: '24px',
    marginBottom: '14px'
}

const descriptionStyle: CSS.Properties = {
    fontSize: '17px',
    lineHeight: '24px',
    letterSpacing: '0.36px',
    textAlign: 'center',
    color: '#333b44',
    marginTop: '14px',
    marginBottom: "24px"
}

export default withRouter(({ history }) => {

    useTitle('Connected Account')
    
    useEffect(() => {
        setBackButtonHidden(true)
        postAnalytics('PWA Change Connected Account Fail')
    }, [])
    
    return (
        <div className="Page" id="PaymentErrorPage">

            <CardView style={cardStyle} >

                <img src={logo} alt="Error Logo" />

                <p className="TitleLabel" style={titleStyle} >Oops! Something went wrong</p>

                <p style={descriptionStyle}>Please check your network connection and try again.</p>

                <button type="button" className="btn btn-primary btn-block" onClick={ () => history.replace('/payment/addDebitCard') }>
                    Try again
                </button>

            </CardView>

        </div>
    )
})