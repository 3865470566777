import React from 'react'
import * as CSS from 'csstype'
import './ErrorBox.css'


export interface Props {
    style?: CSS.Properties,
    errorMessage: string
}

const ErrorBox: React.FC<Props> = ({style, errorMessage }: Props) =>  {
    return (
        <React.Fragment>
            <span className="ErrorBox" style={{color: "#C1372A", textAlign: 'left'}}>
               {errorMessage}
            </span>
        </React.Fragment>
    )
}

export default ErrorBox