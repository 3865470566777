import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as CSS from 'csstype'


const ColorCircularProgress = withStyles({
  root: {
    color: '#1EA3CE',
  },
})(CircularProgress)

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
}))
const progressBox: CSS.Properties = {
  textAlign: "center"
}


type Props = {
}

const Spinner: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div style={progressBox}>
      <ColorCircularProgress className={classes.progress} />
    </div>

  )
}

export default Spinner