import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { useState, useEffect } from 'react'
import getBaseURL from '../utils'
import { logInfo, logError } from '../../common/log'
import { getAuthToken } from '../../common/authToken'

const URL_SAVED_BILLING = 'pay/billing'
const URL_CREATE_CARD = 'pay/payment_method'
const URL_UPDATE_BANK = 'pay/billing/bank_detail'

interface TokenResponse {
    client_token: string
}

class NonceRequest {
    constructor(nonce: string, deviceData?: string) {
        this.payment_method_token = nonce
        this.device_data = deviceData
    }
    payment_method_token: string
    device_data?: string
}

export type InternalPaymentType = 'external bank' | 'external card' | 'none'
export type BraintreePaymentType =  'CARD' | 'PAYPAL'

export type UnifiedPaymentType = 'card' | 'bank' | 'paypal'

interface BilllingAccount {
    account_type: InternalPaymentType
    display_type?: BraintreePaymentType
    last4: string
}

export type BillingResponse = {
    accounts: BilllingAccount[]
}

export const mapUnifiedPaymentTypeFrom = (account: BilllingAccount): UnifiedPaymentType | null => {
    const logAccount = account || {}

    if (!account.display_type) {
        switch (account.account_type) {
            case 'external card':
                return 'card'
            case 'external bank':
                return 'bank'
            case 'none':
                return null
        }
    } else {
        switch (account.display_type) {
            case 'CARD':
                return 'card'
            case 'PAYPAL':
                return 'paypal'
        }
    }
    return null
}

export default class PaymentManager {
    private static instance: PaymentManager
    private networkInstance: AxiosInstance
    private authToken: string = ''

    // A bandaid to bootstrap the initial guided onboarding flow until we can turn the payment
    // flow into useful components/switch to react native.
    private isOnboarding: boolean = false

    private constructor() {
        this.networkInstance = Axios.create({
            baseURL: getBaseURL(window.location.hostname),
            timeout: 30000,
            headers: { 'Authorization': '' }
        })
    }

    static getInstance(): PaymentManager {
        if (!PaymentManager.instance) {
            PaymentManager.instance = new PaymentManager()
        }
        return PaymentManager.instance
    }

    private setAuthorizationHeader(authToken: string) {
        this.networkInstance.defaults.headers['Authorization'] = authToken
    }

    private cancelToken?: () => void

    //API methods
    public setOnboardingStatus(isOnboarding: boolean) {
        this.isOnboarding = isOnboarding
    }

    public getOnboardingStatus() {
        return this.isOnboarding
    }

    public getSuccessUrl(): string {
        if (this.isOnboarding) {
            return '/onboarding/complete_topup'
        }

        return '/payment/accountConnected'
    }

    public getToken(): Promise<string>{
        if (this.cancelToken) this.cancelToken()

        const [tokenPromise, cancelToken] = getAuthToken()
        this.cancelToken = cancelToken
        return tokenPromise
            .then(authToken => this.setAuthorizationHeader(authToken))
            .then(() => this.networkInstance.post<TokenResponse>('pay/client_token'))
            .then(response => this.authToken = response.data.client_token)
            .then(token => token)
            .catch(error => 'Token:' + this.networkInstance.defaults.headers['Authorization'] + ' Error: ' + error)
    }

    public getSavedMethod(): Promise<{ type: UnifiedPaymentType | null, last4: string }> {
        if (this.cancelToken) this.cancelToken()
        const [tokenPromise, cancelToken] = getAuthToken()
        this.cancelToken = cancelToken
        return tokenPromise
            .then(authToken => this.setAuthorizationHeader(authToken))
            .then(() => this.networkInstance.get<BillingResponse>(URL_SAVED_BILLING))
            .then(function (response): { type: UnifiedPaymentType | null, last4: string } {
                try {
                    const accountType = mapUnifiedPaymentTypeFrom(response.data.accounts[0])
                    return {
                        type: accountType,
                        last4: 'XXXX'
                    }
                } catch (err) {
                    return {
                        type: null,
                        last4: 'XXXX'
                    }
                }
            })
    }

    public SendRequest(nonce: string, deviceData?: string): Promise<any>{
        let request = new NonceRequest(nonce, deviceData)
        const options: AxiosRequestConfig = { validateStatus: (status) => (status === 201) }
        if (this.cancelToken) this.cancelToken()
        const [tokenPromise, cancelToken] = getAuthToken()
        this.cancelToken = cancelToken
        return tokenPromise
            .then(authToken => this.setAuthorizationHeader(authToken))
            .then(() => this.networkInstance.post<any>(URL_CREATE_CARD, request, options))
            .catch(error => {
                const statusCode = error.response.status
                var message = 'Saving debit card failed'
                if (statusCode === 400) {
                    message = 'Only debit cards are accepted'
                }
                return Promise.reject({ statusCode,  message })
            })
    }

    public UpdateBankDetail(bankDetails: any): Promise<boolean>{
        const [tokenPromise, cancelToken] = getAuthToken()
        this.cancelToken = cancelToken
        return tokenPromise
            .then(authToken => this.setAuthorizationHeader(authToken))
            .then(() => this.networkInstance.post<any>(URL_UPDATE_BANK, bankDetails))
            .then(response => true)
            .catch(error => false)
    }

}

export const useHasPayment = (): boolean | null => {
    var [hasPayment, setHasPayment] = useState<boolean | null>(null)
    useEffect(() => {
        PaymentManager
            .getInstance()
            .getSavedMethod()
            .then(response => response.type !== null)
            .then(hasPayment => setHasPayment(hasPayment))
            .catch(err => {
                logError(err)
                setHasPayment(null)
            })
    }, [])
    return hasPayment
}
