import React, { useEffect, useState } from 'react'
import FilledButton from "../../../components/FilledButton/FilledButton"
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import DOB from '../../../components/DOB/DOB'

import { makeStyles } from '@material-ui/core/styles'

import { useStoreActions, Actions, useStoreState  } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'

import moment from 'moment'
import SignUpManager from '../SignUpManager'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "8px",

  },
  paper: {
    margin: "16px",
    padding: "15px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

export interface Props {
  action?: () => void
}

const DetailsDOBPage: React.FC<Props> = ({ action }, ...rest) => {
  const classes = useStyles(rest)
  
  const child = useStoreState(state => state.Child)
  const storeSetDOB = useStoreActions((actions: Actions<StoreModel>) => actions.Child.setDOB)
  
  //hook
  const [selectedDate, setSelectedDate] = useState(null as Date | null)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    document.title = "Add My Child"
  }, [])

  useEffect(() => {
    if (selectedDate !== null) {
      storeSetDOB(moment(selectedDate).format("YYYY-MM-DD"))
    }
  }, [selectedDate])

  //action
  const handleClick = () => {
    if (selectedDate !== null) {
      // storeSetActiveScreen("DetailsEmailPage")
      // return action ? action() : null
      setSaving(true)
      setTimeout(() =>
        SignUpManager.getInstance()
          .addChild(child)
          .then(() => {
            action && action()
          })
          .finally(() => {
            setSaving(false)
          })
      , 1000)
    }
  }

  const saveButtonTitle = !saving
    ? `Save ${child.firstName}’s Profile`
    : `Saving ${child.firstName}’s Profile...`

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12}>
          <p className="TitleLabel">
            And when is {(child.firstName) ? (`${child.firstName}’s`) : ('their')} birthday?
          </p>
        </Grid>
        <Grid item xs={12}>
          <p style={{fontSize: '17px', lineHeight: '20px', letterSpacing: '0.61', marginBottom: '24px'}}>
            Children need to be 6-17 years old
          </p>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginTop: '0px', marginBottom: '45px'}}>
            <DOB
              min={moment().subtract(17, 'years').startOf('day').toDate()}
              max={moment().subtract(6, 'years').endOf('day').toDate()}
              onReady={setSelectedDate}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <FilledButton title={saveButtonTitle} action={() => handleClick()} invalid={selectedDate === null || saving}/>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DetailsDOBPage
