import React, { useState, useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import MobileStepper from '@material-ui/core/MobileStepper'

import CardView from '../../components/CardView/CardView'
import '../../components/Page/Page.css'
import { Link } from 'react-router-dom'
import { postAnalytics, setBackButtonHidden, setLogoutButtonHidden } from '../../common/postMessage'

import useTitle from '../../common/useTitle'

import './InfoPage.scss'
import {ReactComponent as SendMoneyQuickly} from '../../assets/images/onboarding/send_money_quickly.svg'
import {ReactComponent as AutomatePocketMoney} from '../../assets/images/onboarding/automate_pocket_money.svg'
import {ReactComponent as SimplifyJobs} from '../../assets/images/onboarding/simplify_jobs.svg'
import {ReactComponent as BuildSavingsHabits} from '../../assets/images/onboarding/build_savings_habits.svg'

var classNames = require('classnames');

export interface Props {
    action?: () => void
}

const InfoPage: React.FC<Props> = ({ action }) => {

    useEffect(() => {
        setBackButtonHidden(false)
        setLogoutButtonHidden(true)

        postAnalytics("Guided Onboarding - Info Page")
    }, [])

    const [step, setStep] = useState(0)

    return (
        <div style={{ height: '100%' }} className="Page" id="StartPage">
            <div style={{ height: '100%', margin: '0 16px', overflowX: 'hidden' }}>
            <CardView style={{ padding: '16px 0', margin: '32px 0' }}>
                <div style={{ overflow: 'visible' }}>
                    <p style={{ fontSize: '20px', marginBottom: '20px' }}>
                        With Spriggy you can...
                    </p>
                    <SwipeableViews
                        enableMouseEvents
                        style={{ overflow: 'visible' }}
                        slideStyle={{ overflow: 'visible' }}
                        containerStyle={{ overflow: 'visible' }}
                        onChangeIndex={ (index) => { setStep(index) } }>
                        <div>
                            <div style={{width: '100%', overflow: 'hidden' }}>
                                <SendMoneyQuickly style={{width:'100%'}} />
                            </div>
                            <div style={{ padding: '16px' }}>
                                <p style={{ fontFamily: 'Bariol-Bold', fontSize: '18px' }}>
                                    Send money quickly
                                </p>
                                <p style={{ fontSize: '16px' }}>
                                    Send money to your kid's Spriggy card within minutes, to use when they need it most.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div style={{width: '100%', overflow: 'hidden' }}>
                                <AutomatePocketMoney style={{width:'100%'}} />
                            </div>
                            <div style={{ padding: '16px' }}>
                                <p style={{ fontFamily: 'Bariol-Bold', fontSize: '18px' }}>
                                    Automate pocket money payments
                                </p>
                                <p>
                                    Give your kids the financial independence to manage their own money.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div style={{width: '100%', overflow: 'hidden' }}>
                                <SimplifyJobs style={{width:'100%'}} />
                            </div>
                            <div style={{ padding: '16px' }}>
                                <p style={{ fontFamily: 'Bariol-Bold', fontSize: '18px' }}>
                                    Simplify jobs for your kids
                                </p>
                                <p>
                                    Help at home has arrived! Give your kids the opportunity to earn an honest dollar.
                                </p>
                            </div>
                        </div>
                        <div style={{ position: 'relative', overflow: 'visible' }}>
                            <div style={{width: '100%', overflow: 'hidden' }}>
                                <BuildSavingsHabits style={{width:'100%'}} />
                            </div>
                            <div style={{ padding: '16px' }}>
                                <p style={{ fontFamily: 'Bariol-Bold', fontSize: '18px' }}>
                                    Build savings habits for the future
                                </p>
                                <p>
                                    Set visual savings goals to build powerful savings habits that last a lifetime.
                                </p>
                                <p>
                                    <Link to="/onboarding/topup">
                                        <button className="btn btn-primary btn-lg btn-block">
                                            Awesome!
                                        </button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </SwipeableViews>
                    <div className="steps">
                        <div className={ classNames("circle", { active: (step == 0)} )}></div>
                        <div className={ classNames("circle", { active: (step == 1)} )}></div>
                        <div className={ classNames("circle", { active: (step == 2)} )}></div>
                        <div className={ classNames("circle", { active: (step == 3)} )}></div>
                    </div>
                </div>

            </CardView>
            </div>
        </div>
    )
}

export default InfoPage
