import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import getBaseURL from '../utils'
import { logInfo, logError } from '../../common/log'
import { getAuthToken } from '../../common/authToken'

import OnboardingAPI from './API'


export default class OnboardingManager {
    private static instance: OnboardingManager
    private networkInstance: AxiosInstance
    private authToken: string = ''

    private constructor() {
        this.networkInstance = Axios.create({
            baseURL: getBaseURL(window.location.hostname),
            timeout: 30000,
            headers: { 'Authorization': '' }
        })
    }

    static getInstance(): OnboardingManager {
        if (!OnboardingManager.instance) {
            OnboardingManager.instance = new OnboardingManager()
        }
        return OnboardingManager.instance
    }

    private setAuthorizationHeader(authToken: string) {
        this.networkInstance.defaults.headers['Authorization'] = authToken
    }

    private cancelToken?: () => void

    public markAsViewed(): void {
        console.log("Marking guided onboarding as viewed.")
        OnboardingAPI.getInstance<OnboardingAPI>().markOnboardingViewed()
    }
}
