import React, { useState } from 'react'
import FilledButton from '../../../components/FilledButton/FilledButton'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import * as CSS from 'csstype'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { useStoreState } from 'easy-peasy'
import SignUpManager from '../SignUpManager'
import { anyAddress } from '../../../common/utils/formatters'
import useTitle from '../../../common/useTitle'
import { saveAuthToken } from '../../../common/postMessage'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "8px",

  },
  paper: {
    margin: "16px",
    padding: "15px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textField: {

  },
}))

const descriptionStyle: CSS.Properties = {
  fontSize: "13px",
  lineHeight: "16px",
  letterSpacing: "0.36px",
  color: "#333b44",
  marginTop: "20px",
  marginBottom: "0px",
  textAlign: "justify"
}

export interface Props {
  action?: (success: boolean) => void
}

const ConfirmDetailPage: React.FC<Props> = ({ action }, ...rest) => {
  
  const classes = useStyles(rest)

  const storeGetPerson = useStoreState(state => state.Parent)

  const [saving, setSaving] = useState(false)

  const { firstName, lastName, dob, email, referralCode, manualAddress, validatedAddress, passcode } = storeGetPerson

  //action
  const handleClick = () => {

    const token = SignUpManager.getInstance().tk()

    setSaving(true)
    SignUpManager
      .getInstance()
      .saveUserProfile(storeGetPerson)
      .then((savedUser) => saveAuthToken(savedUser.id, firstName, token, passcode))
      .then(() => setTimeout(() => action && action(true), 100))
      .catch(() => setTimeout(() => action && action(false), 100))
      .finally(() => setSaving(false))

  }

  useTitle('Sign Up Spriggy')

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <div style={{position: 'relative', zIndex: 10}}>
          <div style={{position: 'relative', zIndex: 'initial'}}>
            <Grid container style={{position: 'relative', zIndex: -1}}>
              <Grid item xs={12}>
                <p className="TitleLabel" style={{marginBottom: '0px'}}>
                  Are these details correct?
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-name"
                  label="FIRST NAME"
                  className={classes.textField}
                  value={firstName}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-lastname"
                  label="LAST NAME"
                  className={classes.textField}
                  value={lastName}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-dob"
                  label="BIRTHDAY"
                  className={classes.textField}
                  value={dob}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-email"
                  label="EMAIL ADDRESS"
                  className={classes.textField}
                  value={email}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-address"
                  label="ADDRESS"
                  className={classes.textField}
                  value={anyAddress(validatedAddress || null, manualAddress || null) || ''}
                  margin="normal"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                  { referralCode ?
                    <TextField
                      id="standard-code"
                      label="REFERRAL CODE"
                      className={classes.textField}
                      value={referralCode}
                      margin="normal"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  : "" }
              </Grid>          
            </Grid>
          </div>
        </div>

        <Grid container>
          <Grid item xs={12}>
            <p style={descriptionStyle}>
              By continuing, you accept the terms and conditions in the Product Disclosure Statements, Financial Services Guide, Privacy Policy and Direct Debit Request Service Agreement. View in full
           </p>
          </Grid>
          <Grid item xs={12}>
            <div style={{marginTop: '25px'}}>
              <FilledButton title={saving ? 'Saving...' : 'Accept and Continue'} 
              action={() => handleClick()} 
              invalid={saving}
              />
            </div>
          </Grid>
        </Grid>

      </Paper>
    </React.Fragment>
  )
}

export default ConfirmDetailPage
