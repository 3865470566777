import React, { useRef, useState, useEffect } from 'react'
import { Client, dataCollector } from 'braintree-web'
import PaymentManager from './PaymentManager'

import Spinner from '../../components/Spinner/Spinner'
import CardView from '../../components/CardView/CardView'
import IconedTitle from '../../components/IconedTitle/IconedTitle'

import paypal1x from "../../assets/images/image1x/paypal.png";
import paypal2x from "../../assets/images/image2x/paypal.png";
import paypal3x from "../../assets/images/image3x/paypal.png";

import { withRouter } from 'react-router'

import '../../components/Page/Page.css'
import './AddDebitCardPage.css'
import { getEnvironment } from '../utils'

declare const braintree: any
declare const paypal: any

const SANDOBX = getEnvironment() !== "production"

const useClient = (): Client | null => {
    const [client, setClient] = useState(null as Client | null)
    useEffect(() => {
        PaymentManager
            .getInstance()
            .getToken()
            .then(authorization => braintree.client.create({ authorization }))
            .then(client => setClient(client))
    }, [])
    return client
}

export default withRouter(({ history }) => {
    const paypalContainer = useRef<HTMLDivElement>(null)
    const [paypalReady, setPaypalReady] = useState(false)

    const client = useClient()
    const [deviceData, setDeviceData] = useState(null as null | string)
    const [error, setError] = useState(null as string | null)

    // DEVICE DATA
    useEffect(() => {
        if (!client) {
            return
        }

        var teardDown = () => {}

        const timeout = setTimeout(function () {
            console.log('Getting device data timed out')
            setDeviceData('fake_device_data')
        }, 3000)

        dataCollector.create({
            client: client,
            paypal: true,
            kount: true
        }).then(dataCollectorInstance => {
            clearTimeout(timeout)
            setDeviceData(dataCollectorInstance.deviceData)
            teardDown = () => dataCollectorInstance.teardown()
        }).catch(error => {
            console.log('Could not get device data', error)
        })

        return () => {
            clearTimeout(timeout)
            teardDown()
        }

    }, [client])

    // PAYPAL
    useEffect(() => {
        if (!paypalContainer.current) {
            return
        }

        if (client === null) {
            return
        }

        if (deviceData === null) {
            return
        }

        var teardownPaypal = () => {}

        braintree.paypalCheckout
            .create({ client })
            .then((paypalInstance: any) => {
                teardownPaypal = () => paypalInstance.teardown()
                return paypalInstance
            })
            .then((paypalCheckout: any) => paypal.Button.render({
                env: SANDOBX ? 'sandbox' : 'production',
                style: {
                    shape: 'rect',
                    color: 'silver',
                    label: 'pay',
                    height: 46,
                    size: 'responsive',
                    tagline: false
                },
                payment: () => paypalCheckout.createPayment({
                    flow: 'vault',
                    offerCredit: false
                }),
                onAuthorize: (data: any) => paypalCheckout
                    .tokenizePayment(data)
                    .then((payload: {nonce: string}) => payload.nonce)
                    .then((nonce: string) => PaymentManager.getInstance().SendRequest(nonce, deviceData))
                    .then(() => {
                        history.replace(PaymentManager.getInstance().getSuccessUrl())
                    })
                    .catch((error: any) => alert(`Could not complete. Error(${error.statusCode}).`))
                ,
                onCancel: (data: any) => {
                    console.log('checkout.js payment cancelled', JSON.stringify(data));
                },
                onError: (err: any) => {
                    alert('We couldn‘t connect to your PayPal account at this time.\n\nYou can either connect a debit card instead or try again in a few moments.')
                    console.error('checkout.js error', err);
                }
            }, paypalContainer.current!.id))
            .then(() => setPaypalReady(true))
            .catch((paypalCreateError: any) => {
                console.error(paypalCreateError)
            })

        return () => teardownPaypal()

    }, [client, paypalContainer, deviceData, history])

    // TEMPLATE

    return (
        <div>
            <p style={{color: 'red', fontWeight: 'bold'}}>{error}</p>

            {(paypalReady !== true) && (
                <div style={{ position: 'absolute', zIndex: 99999, top: '0px', left: '0px', right: '0px', bottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner />
                </div>
            )}

            <div className="Page" id="AddDebitCardPage" style={{textAlign: 'left'}}>
                <CardView>
                    <div className="mb-4">
                        <IconedTitle
                            title="Connect your PayPal account"
                            discription="Top Ups with PayPal incur a 1.5% surcharge."
                            logoSrc={paypal1x}
                            logoSrcSet={`${paypal1x} 1x, ${paypal2x} 2x, ${paypal3x} 3x`}
                            withLock={false}
                        />
                    </div>
                    <div>
                        <div id="paypal-button" ref={paypalContainer} style={{width: '100%', height: '46px', backgroundColor: '#EEEEEE', boxShadow: '0px 10px 10px -10px #57687470', borderRadius: '6px', overflow: 'hidden', marginBottom: '8px'}}></div>
                    </div>
                </CardView>
                <div style={{height: '40px'}}></div>
            </div>
        </div>
    )
})
