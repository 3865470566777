import React, { FC, useState, useEffect, HTMLAttributes } from 'react'
import './Passcode.scss'

interface PasscodeProps extends HTMLAttributes<HTMLDivElement> {
    name: string
    initialValue?: string
    autoFocus?: boolean
    onComplete: (passcode: string) => void
}

const usePasscode = (passcode: string, maxLength: number): boolean[] => {
    const [dots, setDots] = useState([] as boolean[])
    useEffect(() => {
        setDots(new Array<boolean>(maxLength).fill(false).map((_, i) => typeof passcode[i] === 'string'))
    }, [passcode, maxLength])
    return dots
}

const useAutofocus = (input: HTMLInputElement | null, shouldAutofocus: boolean) => {
    useEffect(() => {
        if (!shouldAutofocus) {
            return
        }
        if (!input) {
            return
        }
        input.focus()
    }, [input, shouldAutofocus])
}

const Passcode: FC<PasscodeProps> = ({
    name,
    initialValue,
    autoFocus = true,
    onComplete,
    ...rest
}) => {

    const [passcode, setPasscode] = useState(initialValue || '')
    const [input, setInput] = useState(null as HTMLInputElement | null)

    const displayPasscode = usePasscode(passcode, 4)
    useAutofocus(input, autoFocus)

    const handlePasscodeChange = (newPasscode: string) => {
        newPasscode = newPasscode.slice(0, 4)
        const oldPasscode = passcode
        setPasscode(newPasscode)
        if (newPasscode.length === 4 && newPasscode !== oldPasscode) {
            onComplete(newPasscode)
        }
    }
    
    return (
        <div className="Passcode" onClick={() => input && input.focus()} {...rest}>
            <input value={passcode} autoFocus={true} type="number" pattern="\d*" name="" id="" onChange={(evt) => handlePasscodeChange(evt.currentTarget.value)} ref={setInput} />
            <div className="Passcode-chars">
                {displayPasscode.map((filled, index) => (filled) ? (
                    <span className="Passcode-char Passcode-char--filled" key={`passcode-${name}-${index}`}></span>
                ) : (
                    <span className="Passcode-char" key={`passcode-${name}-${index}`}></span>
                ))}
            </div>
        </div>
    )
}

export default Passcode