import React, { FC } from 'react'
import CardView from '../CardView/CardView'
import Spinner from '../Spinner/Spinner'

interface LoadingCardViewProps {
    title: string
}

const LoadingCardView: FC<LoadingCardViewProps> = ({ title }) => {
    return (
        <CardView>

            <Spinner/>

            <p style={{ margin: '0px', marginTop: '16px', marginBottom: '15px', fontFamily: 'Bariol-Bold', fontSize: '20px', color: '#333B44' }}>
                {title}
            </p>

        </CardView>
    )
}

export default LoadingCardView