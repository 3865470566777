import React, { useContext } from 'react'
import CardView from '../../../components/CardView/CardView'
import FilledButton from '../../../components/FilledButton/FilledButton'
import * as CSS from 'csstype'

import { useStoreState } from 'easy-peasy'

import logo from '../../../assets/icon/welcome.png'

import {__RouterContext} from "react-router"
import useTitle from '../../../common/useTitle'

const cardStyle: CSS.Properties = {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: "32px"
}

const giantTitleStyle: CSS.Properties = {
    fontSize: '28px',
    lineHeight: '23px',
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#333b44',
    marginTop: "28px",
    marginBottom: "28px"
}

const titleStyle: CSS.Properties = {
    marginBottom: '24px'
}

const descriptionStyle: CSS.Properties = {
    fontSize: '17px',
    lineHeight: '24px',
    letterSpacing: '0.36px',
    textAlign: 'center',
    color: '#333b44',
    marginBottom: "28px"
}

const SignUpSuccessPage: React.FC = () => {
    const routerContext = useContext(__RouterContext)
  
    const storeGetPerson = useStoreState(state => state.Parent)
    const { firstName } = storeGetPerson

    useTitle('Sign Up Spriggy')
    
  //action
  const gotoNextPage = () => {
    routerContext.history.push("/signup/childInfo/name")
  }

    return (
        <div 
            className="Page" 
            id="signUpSuccessPage"
        >
            <CardView style={cardStyle} >
                <img src={logo} alt="Green Tick" />
                <p style= {giantTitleStyle} >Welcome, {firstName}!</p>
                <p 
                    className="TitleLabel" 
                    style= {titleStyle} 
                >
                    Let’s add your child
                </p>
                <p style={descriptionStyle}>Each child you add to Spriggy gets a free 30-day trial which only starts when you activate their pre-paid Visa card. After 30 days it’s $30 per year per child.</p>
                <FilledButton 
                    title= "Add My Child" 
                    action={gotoNextPage} 
                />
            </CardView>
        </div>
    )
}

export default SignUpSuccessPage