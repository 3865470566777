import React, { FC, useContext } from 'react'
import { BrowserRouter as Router, Route, match, Switch } from "react-router-dom"

import EnterMobileNumberPage from './pages/signup/EnterMobileNumberPage'
import ErrorPage from './pages/signup/ErrorPage'
import Index from './pages/payment/Index'
import AccountConnectedPage from './pages/payment/AccountConnectedPage'
import ConnectedAccountsPage from './pages/payment/ConnectedAccountsPage'
import PaymentErrorPage from './pages/payment/PaymentErrorPage'
import VerifyMobileNumberPage from './pages/signup/VerifyMobileNumberPage'
import SignUpSuccessPage from './pages/signup/parent/SignUpSuccessPage'
import AddChildPage from './pages/signup/children/AddChildPage'
import EnterPasscode from './pages/signup/passcode/EnterPasscode'
import ConfirmPasscode from './pages/signup/passcode/ConfirmPasscode'
import SetupPage from './pages/signup/SetupPage'
import DetailsNamePage from './pages/signup/parent/DetailsNamePage'
import DetailsEmailPage from './pages/signup/parent/DetailsEmailPage'
import ParentDetailsDOBPage from './pages/signup/parent/DetailsDOBPage'
import ChildDetailsDOBPage from './pages/signup/children/ChildDOBPage'
import ConfirmDetailPage from './pages/signup/parent/ConfirmDetailPage'
import CardDesignSelectionPage from './pages/signup/children/CardDesignSelectionPage'
import ChildAddAnotherChild from './pages/signup/children/ChildAddAnotherChild'
import EnterManualAddress from './pages/signup/parent/EnterManualAddress'
import ConfirmCharity from './pages/charity/ConfirmCharity'
import SelectAccount from './pages/charity/SelectAccount'
import SelectAmount from './pages/charity/SelectAmount'
import StartPage from './pages/onboarding/StartPage'
import InfoPage from './pages/onboarding/InfoPage'
import TopUpPrompt from './pages/onboarding/TopUpPrompt'
import TopUpPromptDismissal from './pages/onboarding/TopUpPromptDismissal'
import TopUpModalDismissal from './pages/onboarding/TopUpModalDismissal'
import CompleteTopUp from './pages/onboarding/CompleteTopUp'
import TopUpSuccess from './pages/onboarding/TopUpSuccess'
import TopUpFailure from './pages/onboarding/TopUpFailure'
import ConnectAccountDismissal from './pages/onboarding/ConnectAccountDismissal'

import { StoreProvider, useStoreActions, Actions } from 'easy-peasy'

import './App.css'
import store from './common/contexts/store'

import 'bootstrap/dist/css/bootstrap-grid.css'
import 'bootstrap/dist/css/bootstrap.css'
import { __RouterContext } from 'react-router'
import AddressPage from './pages/signup/parent/AddressPage'
import { goToSavedLogin, showCancelButton, setBackButtonHidden, postDismiss } from './common/postMessage'
import { StoreModel } from './common/contexts/model/Index'
import DonateSuccess from './pages/charity/DonateSuccess'
import ConfirmDonation from './pages/charity/ConfirmDonation'
import ChoosePaymentMethodPage from './pages/payment/ChoosePaymentMethodPage'
import AddPayPalPage from './pages/payment/AddPayPalPage'
import AddDebitCardPage from './pages/payment/AddDebitCardPage'
import AddBankAccountPage from './pages/payment/AddBankAccountPage'

const App: React.FC = () => {
  return (
    <StoreProvider store={store}>
      <div className="App">
        <div id="topFixer"></div>
        <Router>
          <Switch>
            <Route component={SignUp} path="/signup/:page/:step" />
            <Route component={SignUp} path="/signup/:page" />
            <Route component={SignUp} path="/signup" exact />
            <Route component={PaymentMethod} path="/payment/:page" />
            <Route component={PaymentMethod} path="/payment/" exact />
            <Route component={Charity} path="/charity" exact />
            <Route component={Charity} path="/charity/:page" />
            <Route component={StartPage} path="/onboarding" exact />
            <Route component={InfoPage} path="/onboarding/info" />
            <Route component={TopUpPrompt} path="/onboarding/topup" exact />
            <Route component={TopUpPromptDismissal} path="/onboarding/topup/dismiss" />
            <Route component={TopUpModalDismissal} path="/onboarding/topup/dismiss_modal" />
            <Route component={CompleteTopUp} path="/onboarding/complete_topup" />
            <Route component={TopUpSuccess} path="/onboarding/topup_success" />
            <Route component={TopUpFailure} path="/onboarding/topup_failure" />
            <Route component={ConnectAccountDismissal} path="/onboarding/connect_account_dismissal" />
            <Route component={UrlNotFound} />
          </Switch>
        </Router>
      </div>
    </StoreProvider>
  )
}

interface MatchParams {
  page: string
  step: string
}

interface PageProps {
  required: string
  match?: match<MatchParams>
}

const Charity: FC<PageProps> = ({match}) => {
  let {history} = useContext(__RouterContext)
  if (!match) {
    return <ErrorPage />
  }
  const {page, step} = match.params
  switch (page || '') {
    case '':
    case 'selectAccount':
      return (<SelectAccount action={() => history.push('/charity/selectAmount')}/>)
    case 'selectAmount':
      return (<SelectAmount action={() => history.push('/charity/confirmDonation')}/>)
    case 'confirmCharity':
      return (<ConfirmCharity action={(success, accepted) => success
        ? ( accepted
          ? history.replace('/charity/selectAccount')
          : postDismiss()
          )
        : history.push('/charity/error')}
      />)
    case 'confirmDonation':
      return (<ConfirmDonation action={(success) => success
        ? history.push('/charity/success')
        : history.push('/charity/error')}
      />)
    case 'success':
      return (<DonateSuccess />)
    default:
      return (<ErrorPage />)
  }
}

const SignUp: FC<PageProps> = ({match}) => {
  let {history} = useContext(__RouterContext)
  const storeResetChild = useStoreActions((actions: Actions<StoreModel>) => actions.Child.resetChild)
  if (!match) {
    return <ErrorPage />
  }
  const {page, step} = match.params
  switch (page || '') {
    case '':
    case 'enterMobileNumber':
      return (<EnterMobileNumberPage />)
    case 'verifyMobileNumber':
      return (<VerifyMobileNumberPage />)
    case 'passcode':
      return (<EnterPasscode />)
    case 'passcodeConfirm':
      return (<ConfirmPasscode />)
    case 'signUpSuccess':
      return (<SignUpSuccessPage />)
    case 'parentInfo':
      switch (step || '') {
        case '':
        case 'name':
          return <DetailsNamePage action={() => history.push('/signup/parentInfo/dateOfBirth')} />
        case 'dateOfBirth':
          return <ParentDetailsDOBPage action={() => history.push('/signup/parentInfo/email')} />
        case 'email':
          return <DetailsEmailPage action={() => history.push('/signup/parentInfo/address')} />
        case 'address':
          return <AddressPage action={didFindAddress => !didFindAddress
            ? history.push('/signup/parentInfo/enterManualAddress')
            : history.push('/signup/parentInfo/confirm')
          } />
        case 'enterManualAddress':
          return <EnterManualAddress action={() => history.replace('/signup/parentInfo/confirm')}/>
        case 'confirm':
          return <ConfirmDetailPage action={success => {
            history.go(-9)
            success
              ? history.push('/signup/signUpSuccess')
              : history.push('/signup/parentInfo/error')
          }} />
        default:
          return (<ErrorPage />)
      }
    case 'childInfo':
      switch (step || '') {
        case 'name':
          return <AddChildPage action={() => history.push('/signup/childInfo/cardDesign')} />
        case 'cardDesign':
          return <CardDesignSelectionPage action={() => history.push('/signup/childInfo/dateOfBirth')} />
        case 'dateOfBirth':
          return <ChildDetailsDOBPage action={() => history.push('/signup/childInfo/addOtherChild')} />
        case 'addOtherChild':
          return <ChildAddAnotherChild action={() => goToSavedLogin() } actionReset={() => {
            showCancelButton()
            storeResetChild()
            history.go(-3)
          }} />
        default:
          return (<ErrorPage />)
      }
    case 'setupPage':
      return (<SetupPage />)
    default:
      return (<ErrorPage />)
  }
}

const compareMatch = (match: match<MatchParams>, route: string):boolean => {
  return match.params.page.toUpperCase() === route.toUpperCase()
}

const PaymentMethod: FC<PageProps> = ({ match }) => {
  setBackButtonHidden(false)

  if (match) {
    if (match.params.page === undefined || match.params.page === "") {
      return (<Index />)
    } else if (compareMatch(match, 'ChoosePaymentMethod')) {
      return (<ChoosePaymentMethodPage />)
    } else if (compareMatch(match, 'AddBankAccount')) {
      return (<AddBankAccountPage />)
    } else if (compareMatch(match, 'AddPayPal')) {
      return (<AddPayPalPage/>)
    } else if (compareMatch(match, 'addDebitCard')) {
      return (<AddDebitCardPage />)
    } else if (compareMatch(match, 'accountConnected')) {
      return (<AccountConnectedPage />)
    } else if (compareMatch(match, 'connectedAccounts')) {
      return (<ConnectedAccountsPage />)
    } else if (compareMatch(match, 'error')) {
      return (<PaymentErrorPage />)
    } else {
      return (<PaymentErrorPage />)
    }
  } else {
    return (<ErrorPage />)
  }
}

const UrlNotFound: React.FC = () => {
  return (
    <React.Fragment>
      <p>Please access this site within Spriggy App</p>
      <input type="hidden" id="tokendiv" value="" />
    </React.Fragment>
  )
}

export default App
