import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'

import Style from '../../../common/style/Style'

import './AddressPage.scss'
import ValidatedAddresses from '../../../components/ValidatedAddresses'
import {useValidatedAddresses} from '../../../components/ValidatedAddresses/useValidatedAddresses'
import { fullAddress } from '../../../common/utils/formatters'
import useTitle from '../../../common/useTitle'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: "8px",

    },
    paper: {
        margin: "16px",
        padding: "15px",
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

export interface Props {
    action?: (didFindAddress: boolean) => void
}

const AddressPage: React.FC<Props> = ({ action }, ...rest) => {

    const {validatedAddress} = useStoreState(state => state.Parent)
    const {setValidatedAddress} = useStoreActions((actions: Actions<StoreModel>) => actions.Parent)

    const [validatedAddressInput, setValidatedAddressInput] = useState(undefined as string | undefined)
    const [validatedAddresses, hint, error] = useValidatedAddresses(validatedAddressInput)
    const [selectedValidatedAddress, setSelectedValidatedAddress] = useState(validatedAddress)

    const classes = useStyles(rest)

    useTitle('Sign Up Spriggy')

    // Fill input with validated address if one when component is first loaded

    useEffect(() => {
        if (validatedAddress !== undefined && validatedAddresses === undefined) {
            setValidatedAddressInput(fullAddress(validatedAddress))
        }
    }, [validatedAddress, validatedAddresses])

    // Reset selected address whenever input changes

    useEffect(() => {
        setSelectedValidatedAddress(undefined)
    }, [validatedAddressInput])

    // Save address to store once user has selected one

    useEffect(() => {
        setValidatedAddress(selectedValidatedAddress)
    }, [setValidatedAddress, selectedValidatedAddress])

    // Trigger action once an address has been selected and we have validated addresses

    useEffect(() => {
        if (selectedValidatedAddress !== undefined && validatedAddresses !== undefined) {
            setTimeout(() => action && action(true), 500)
        }
    }, [selectedValidatedAddress, action, validatedAddresses])

    return (
        <div className="AddressPage">

            <Paper className={classes.paper}>

                <Grid container>

                <Grid item xs={12}>
                    <p className="TitleLabel">
                        What is your address?
                    </p>
                </Grid>

                <Grid item xs={12}>

                    <input
                        type="text"
                        className="PlainInput input-address"
                        style={{...Style.inputStandardStyle, marginTop: '20px'}}
                        placeholder="Address"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        autoFocus={validatedAddress !== undefined}
                        value={(validatedAddressInput || '') as string}
                        onChange={e => setValidatedAddressInput(e.currentTarget.value)}
                        onFocus={() => setValidatedAddressInput('') }
                    />

                    {(hint) ? (
                        <p className="message" style={{marginBottom: '15px', marginTop: '5px', color: '#CDCDCD', textAlign: 'left'}}>{hint}</p>
                    ) : (null)}

                    {(error) ? (
                        <p className="message" style={{marginBottom: '15px', marginTop: '5px', color: '#FF0000', textAlign: 'left'}}>{error}</p>
                    ) : (null)}

                    <ValidatedAddresses
                        addresses={validatedAddresses}
                        onSelectAddress={setSelectedValidatedAddress}
                        onCantFindAddress={() => action && action(false)}
                        style={{margin: '16px -16px'}}
                    />
                </Grid>

                </Grid>

            </Paper>

            <div style={{height: '20px'}}></div>

        </div>
    )
}

export default AddressPage
