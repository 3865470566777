import React from 'react'
import BasePasscode from './BasePasscode'
import { withRouter } from 'react-router'
import { useStoreActions, Actions, useStoreState } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'

const ConfirmPasscode = withRouter(({ history }) => {

    const storePasscode = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setPasscode)
    const firstPasscode = useStoreState(state => state.Parent).passcode

    const handlePasscode = (passcode: string) => {
        if (passcode !== firstPasscode) {
            setTimeout(() => alert('Passcode must match!'), 1000)
        } else {
            storePasscode(passcode)
            history.push("/signup/parentInfo/name")
        }
    }

    return (

        <div id="screen-enter-passcode">

            <BasePasscode onPasscodeValid={handlePasscode}>

                <p className="TitleLabel" style={{marginTop: '10px', marginBottom: '25px'}}>
                    Confirm your passcode
                </p>

                <p style={{ fontSize: '17px', lineHeight: '24px', letterSpacing: '0.36px', textAlign: 'center', color: '#333b44', marginBottom: '25px' }}>
                    Please enter that one more time…
                </p>

            </BasePasscode>

        </div>

    )
})

export default ConfirmPasscode