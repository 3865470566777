import React, { FC, useRef, useState } from 'react'
import FilledButton from '../../../components/FilledButton/FilledButton'
import * as CSS from 'csstype'

import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'

import ErrorBox from '../components/ErrorBox'
import InputValidator from '../../../common/utils/InputValidator'
import Style from '../../../common/style/Style'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import useTitle from '../../../common/useTitle'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "8px",

  },
  paper: {
    margin: "16px",
    padding: "15px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const alertTitleStyle: CSS.Properties = {
  fontFamily: 'Bariol-Bold' 
}

export interface Props {
  action?: () => void
}

const DetailsEmailPage: FC<Props> = ({ action }, ...rest) => {
  const emailElement = useRef<HTMLInputElement>(null)
  const passwordElement = useRef<HTMLInputElement>(null)
  const classes = useStyles(rest)
  const [referralCodeModalOpen, setReferralCodeModalOpen] = useState(false)

  const storeGetPerson = useStoreState(state => state.Parent)
  const storeSetEmail = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setEmail)
  const storeSetPassword = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setPassword)
  const storeSetReferralCode = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setReferralCode)

  //hook
  const [emailError, setEmailError] = useState(false)
  const [emailPristine, setEmailPristine] = useState(true)

  const [password, setPassword] = useState(storeGetPerson.password)
  const [passwordError, setPasswordError] = useState('')
  const [passwordPristine, setPassworPristine] = useState(true)

  useTitle('Sign Up Spriggy')

  //action
  const handleChange = (e: any) => {
    e.preventDefault()
    const email = e.currentTarget.value
    if (emailPristine && email !== '') {
      setEmailPristine(false)
    }
    var isValid = InputValidator.emailValidation(email)
    storeSetEmail(email)
    if (isValid) {
      setEmailError(false)
    } else {
      setEmailError(true)
    }
  }

  const handlePassword = (password: string) => {
    if (passwordPristine && password !== '') {
      setPassworPristine(false)
    }
    setPassword(password)
    storeSetPassword(password)
    if (!/[0-9]/.test(password)) {
      setPasswordError('It should contain at least one digit')
    } else if (password.length < 8) {
      setPasswordError('It should contain at least 8 characters')
    } else {
      setPasswordError('')
    }
  }

  const handleClose = () => {
    setReferralCodeModalOpen(false)
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <p className="TitleLabel">
              What is your email address?
            </p>
          </Grid>
          <Grid item xs={12}>
            <input
              ref={emailElement}
              className="PlainInput"
              style={{...Style.inputStandardStyle, marginTop: '25px'}}
              type="email"
              placeholder="Email"
              value={storeGetPerson.email}
              onChange={handleChange}
              autoFocus={true}
            />
            <span style={{ fontWeight: 400, fontSize: '16px', color: "#C1372A", display: "block", textAlign: "left", marginTop: '5px', marginBottom: "25px" }}>
              {
                emailError ? <ErrorBox errorMessage="Please enter a valid email" /> : ""
              }
            </span>
          </Grid>
          <Grid item xs={12}>
            <input
              ref={passwordElement}
              className="PlainInput"
              style={Style.inputStandardStyle}
              type="text"
              placeholder="Password"
              value={password}
              onChange={e => handlePassword(e.currentTarget.value)}
            />
              {(passwordError) ? (
                <span style={{ fontWeight: 400, fontSize: '16px', display: "block", textAlign: "left", marginBottom: "25px", marginTop: '5px' }}>
                  <ErrorBox errorMessage={passwordError} />
                </span>
              ) : (
                <span style={{ fontWeight: 400, fontSize: '12px', color: "#909090", textAlign: "left", marginBottom: "25px", marginTop: '5px',  display: "block" }}>
                  You’ll use this password when you sign in using your email
                </span>
              )}
          </Grid>
          <Grid item xs={12}>
            <FilledButton title="Next"
              action={() => action && action()}
              invalid={(emailPristine && !storeGetPerson.email) || emailError || (passwordPristine && !storeGetPerson.password) || !!passwordError}
            />
          </Grid>
        </Grid>
      </Paper>
      <div style={{marginTop: '40px', color: '#333b44'}}>
        <div style={{fontFamily: 'Bariol-Bold', fontSize: '18px', marginBottom: '10px'}}>
          {!storeGetPerson.referralCode ?
            'Got a referral code?' :
            'Referral Code:'
          }
        </div>
        <div style={{fontSize: '16px', marginBottom: '18px'}}>{
          !storeGetPerson.referralCode ?
            'If so, tap below to enter it now' :
            storeGetPerson.referralCode.toUpperCase()
        }</div>
        <button onClick={() => setReferralCodeModalOpen(true)} style={{
          fontFamily: 'Bariol-Bold',
          border: 'none',
          backgroundColor: '#1ea3ce26',
          color: '#1ea3ce',
          padding: '10px 30px',
          borderRadius: '8px'
        }}>{
          !storeGetPerson.referralCode ?
            'Enter Code Now' :
            'Edit Code'
        }</button>
      </div>

      {(referralCodeModalOpen) ? (
        <Dialog
          open={referralCodeModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <DialogTitle>
            <div style={alertTitleStyle}>Do you have a referral code?</div>
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              <input type="text" className="PlainInput" placeholder="" autoFocus={true} autoComplete="off" autoCorrect="off" value={storeGetPerson.referralCode.toUpperCase()} onChange={(e) => storeSetReferralCode(e.currentTarget.value)}/>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
              <FilledButton
                title="Apply Code"
                action={() => setReferralCodeModalOpen(false)}
                invalid={!storeGetPerson.referralCode}
              >
              </FilledButton>
          </DialogActions>

        </Dialog>
      ) : (null)}

    </React.Fragment>
  )
}

export default DetailsEmailPage
