import React from 'react'
import * as CSS from 'csstype'
import Grid from '@material-ui/core/Grid'
import SetupBox from "../signup/components/SetupBox"

const header: CSS.Properties = {
    backgroundColor: '#676767'
}

const headerText: CSS.Properties = {
    color: '#ffffff',
    fontSize: '70px'

}

const headerSubText: CSS.Properties = {
    color: '#ffffff',
    fontSize: '20px',
    marginBottom: '20px'
}

export interface Props {
    action?: () => void
}

const SetupPage: React.FC<Props> = () => {
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container style={header}>
                        <Grid item xs={12}><div style={headerText}>16%</div></Grid>
                        <Grid item xs={12}><div style={headerSubText}>Get set up for success!</div></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SetupBox header="Confirm mobile number" subText ="Mobile number has been confirmed!" isCompleted = {true}/>
                </Grid>
                <Grid item xs={12}>
                    <SetupBox header="Create Talia’s login" subText ="Decide how your child will log in by setting up a username and password or with mobile number" isCompleted = {false}/>
                </Grid> 
                <Grid item xs={12}>
                    <SetupBox header="Activate your children cards" subText ="You will need to activate your children cards before they can use them" isCompleted = {false}/>
                </Grid>    
                <Grid item xs={12}>
                    <SetupBox header="Connect an Account" subText ="This account will be used to add money to your Parent Wallet." isCompleted = {false}/>
                </Grid>     
                <Grid item xs={12}>
                    <SetupBox header="Set up Pocket Money" subText ="It’s why you are here. Pocket money is a regular payment to your children." isCompleted = {false}/>
                </Grid>  
                <Grid item xs={12}>
                    <SetupBox header="Make your first Top Up" subText ="Add money to your Parent Wallet so you’re ready to send money to your child as quick as you need to." isCompleted = {false} />
                </Grid>   
                <Grid item xs={12}>
                    <SetupBox header="Schedule your Top Ups" subText ="Simplify your life with a regular Top Up to your Parent Wallet." isCompleted = {false}/>

                </Grid>  
                <Grid item xs={12}>
                    <SetupBox header="Start Saving" subText ="Help your children learn to save with a small savings goal." isCompleted = {false}/>
                </Grid>                                                                                                                                  
            </Grid>
        </React.Fragment>
    )
}

export default SetupPage
