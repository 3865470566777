import React, { HTMLAttributes } from 'react'
import './AccountListItem.scss'
import { DonationSourceAccount } from '../../pages/charity/api';

interface AccountListItemProps extends HTMLAttributes<HTMLDivElement> {
    account: DonationSourceAccount,
    filled?: boolean,
}

const AccountListItem: React.FC<AccountListItemProps> = (props) => {
    var circleClass = "";
    if (props.filled) {
        circleClass = "circle-filled "
    }
    switch (props.account.accountType) {
        case "parent unallocated":
            circleClass += "circle-wallet";
            break;
        case "child unallocated":
            circleClass += "circle-savings";
            break;
        case "child spending":
            circleClass += "circle-card";
            break;
    }

    return (
        <div className="AccountListItem" onClick={props.onClick}>
            <div className={`circle ${circleClass}`}></div>
            <div className="text-container flex-grow-1 d-flex flex-column justify-content-between">
                <div className="account-name">
                    {props.account.name}
                </div>
                <div className="balance-container d-flex justify-content-between">
                    <div className="balance-text">
                        Available Balance
                    </div>
                    <div className="balance-amount">
                        ${props.account.balance}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountListItem