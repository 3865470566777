
import { Action, action } from "easy-peasy"
import { DonationSourceAccount } from "../../../pages/charity/api"

export interface DonationModel {
    sourceAccount: DonationSourceAccount | null
    setSourceAccount: Action<DonationModel, DonationSourceAccount>
    amount: string | null
    setAmount: Action<DonationModel, string>
    firstDonation: boolean
    setFirstDonation: Action<DonationModel, boolean>
}


const Donation: DonationModel = {
    sourceAccount: null,
    setSourceAccount: action((state, payload) => {
        state.sourceAccount = payload
    }),
    amount: null,
    setAmount: action((state, payload) => {
        state.amount = payload
    }),
    firstDonation: false,
    setFirstDonation: action((state, payload) => {
        state.firstDonation = payload
    })
}

export default Donation;