import React, { useEffect, useState, useRef } from "react"
import './InputSelect.scss'

type InputValueType = number | string | object

export interface InputOption<T extends InputValueType> {
    value: T
    label: string
}

interface InputSelectProps<T extends InputValueType> {
    options: InputOption<T>[]
    placeholder?: string
    onOptionSelected: (option: InputOption<T>) => void
}

export default function InputSelect<T extends InputValueType>({options, onOptionSelected, placeholder}: InputSelectProps<T>) {

    const selectRef = useRef<HTMLSelectElement>(null)

    const [selectedOption, setSelectedOption] = useState(null as InputOption<T> | null)

    const handleValue = (value: string) => {

        const selectedOption = options.reduce(((prev, newOption) => 
            newOption.value.toString() !== value ? prev : newOption
        ), null as InputOption<T> | null)

        setSelectedOption(selectedOption)
    }

    useEffect(() => {
        if (selectedOption === null) {
            return
        }
        onOptionSelected(selectedOption)
    }, [selectedOption, onOptionSelected])

    return (
        <div className="InputSelect">
            <select ref={selectRef} onChange={e => handleValue(e.currentTarget.value)} style={{color: selectedOption === null ? '#A9A9A9' : '#000000'}}>
                {placeholder ? (
                    <option>{placeholder}</option>
                ) : (null)}
                {options.map((option, i) => (
                    <option key={`option-${i}`} value={option.value.toString()}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    )
}