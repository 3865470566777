import React, { useContext, useEffect } from 'react'
import { __RouterContext } from "react-router"

import * as CSS from 'csstype'
import CardView from '../../components/CardView/CardView'
import '../../components/Page/Page.css'
import { Link } from 'react-router-dom'

import useTitle from '../../common/useTitle'
import {postAnalytics, requestTopUp, setBackButtonHidden} from '../../common/postMessage'

import {ReactComponent as GreenTick} from '../../assets/icon/greentick.svg'


const TopUpPrompt: React.FC = () => {

    useTitle('')

    useEffect(() => {
        setBackButtonHidden(true)
        postAnalytics("Guided Onboarding - Account Connected")
    }, [])

    const routerContext = useContext(__RouterContext)

    const handleTopupResult = (result: [boolean, boolean]) => {
        let [wasSuccess, wasCancelled] = result

        if (wasCancelled) {
            // The user intentionally cancelled the topup modal so just leave
            // them on this screen.
            return
        } else if (wasSuccess) {
            // The top up worked so show them the success screen.
            routerContext.history.push('/onboarding/topup_success')
        } else {
            // The top up failed so show them the failure screen.
            routerContext.history.push('/onboarding/topup_failure')
        }
    }

    const startTopUp = () => {
        requestTopUp().then(handleTopupResult)
    }

    return (
        <div className="Page" id="StartPage">

            <CardView>

                <div style={{ paddingTop: '10px'}}>
                    <GreenTick style={{ marginBottom: '20px' }} />
                    <h1 style={{ fontSize: '22px', fontFamily: 'Bariol-Bold', marginBottom: '30px' }}>
                        Account successfully connected
                    </h1>
                    <p style={{ marginBottom: '50px' }}>
                        Now, complete your top up to send your kids money whenever you want.
                    </p>
                    <p>
                        <button onClick={startTopUp} className="btn btn-primary btn-lg btn-block">
                            Complete my top up
                        </button>
                    </p>
                    <p>
                        <Link to="/onboarding/topup/dismiss_modal">
                            <button style={{fontSize: '18px', fontFamily: 'Bariol'}} className="btn btn-link btn-lg btn-block">
                                I won't be giving money to my kids
                            </button>
                        </Link>
                    </p>
                </div>

            </CardView>
        </div>
    )
}

export default TopUpPrompt
