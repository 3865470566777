import React from 'react'
import BasePasscode from './BasePasscode'
import { withRouter } from 'react-router'
import { useStoreActions, Actions, useStoreState  } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'

const EnterPasscode = withRouter(({ history }) => {

    const storePasscode = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setPasscode)
    const savedPasscode = useStoreState(state => state.Parent).passcode

    const handlePasscode = (passcode: string) => {
        storePasscode(passcode)
        history.push("/signup/passcodeConfirm")
    }

    return (
        <div id="screen-enter-passcode">
            <BasePasscode
                initialValue={savedPasscode}
                onSkip={() => {
                    storePasscode('')
                    setTimeout(() => history.push("/signup/parentInfo"), 100)
                }}
                onPasscodeValid={handlePasscode}
            >
                <p className="TitleLabel" style={{marginTop: '10px', marginBottom: '25px'}}>
                    Set up a passcode
                </p>
                <p style={{ fontSize: '17px', lineHeight: '24px', letterSpacing: '0.36px', textAlign: 'center', color: '#333b44', marginBottom: '25px' }}>
                    Let’s set up a passcode so you<br/>
                    can sign in quickly!
                </p>
            </BasePasscode>
        </div>
    )
})

export default EnterPasscode