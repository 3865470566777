import React, { useContext } from 'react'
import CardView from '../../components/CardView/CardView'
import FilledButton from '../../components/FilledButton/FilledButton'
import * as CSS from 'csstype'
import '../../components/Page/Page.css'
import logo from '../../assets/icon/redcross.svg'
import useTitle from '../../common/useTitle'
import { __RouterContext } from 'react-router'

const cardStyle: CSS.Properties = {
    padding: '24px'
}

const titleStyle: CSS.Properties = {
    marginTop: '24px',
    marginBottom: '14px'
}

const descriptionStyle: CSS.Properties = {
    fontSize: '17px',
    lineHeight: '24px',
    letterSpacing: '0.36px',
    textAlign: 'center',
    color: '#333b44',
    marginTop: '14px',
    marginBottom: "24px"
}

const ErrorPage: React.FC = () => {

    useTitle('')

    let router = useContext(__RouterContext)
    
    return (
        <div 
            className="Page" 
            id="ErrorPage"
        >
            <CardView style={cardStyle} >
                <img 
                    src={logo} 
                    alt="Error Logo" 
                />
                <p 
                    className="TitleLabel" 
                    style={titleStyle} 
                >
                    Oops! Something went wrong
                </p>
                <p style={descriptionStyle}>Please check your network connection and try again.</p>
                <FilledButton title= "Try Again" action={ () => router.history.goBack() }/>
            </CardView>
        </div>
    )
}

export default ErrorPage