import React, { useEffect } from 'react'
import CardView from '../../components/CardView/CardView'
import * as CSS from 'csstype'
import logo from '../../assets/icon/greentick.svg'
import '../../components/Page/Page.css'
import {postAnalytics, postDismiss, setBackButtonHidden} from '../../common/postMessage'

import '../../common/utils/WindowExtend'

import useTitle from '../../common/useTitle'

const descriptionStyle: CSS.Properties = {
    fontSize: '17px',
    lineHeight: '24px',
    letterSpacing: '0.36px',
    textAlign: 'center',
    color: '#333b44',
    marginBottom: "32px"
}

export interface Props {
    action?: () => void
}

const AccountConnectedPage: React.FC<Props> = ({ action }, ...rest) => {

    useTitle('Connected Account')

    useEffect(() => {
        setBackButtonHidden(true)
        postAnalytics('PWA Change Connected Account Success')
    }, [])

    const doneClicked = () => postDismiss()
    
    return (
        <div className="Page" id="AccountConnectedPage">
            <CardView style={{padding:"24px"}}>
                <img src={logo} alt="Green Tick" />
                <p className="TitleLabel">
                    Your account has been connected
                </p>
                <p style={descriptionStyle}>
                    Awesome! You’re now ready to<br /> Top Up
                    your Parent Wallet and<br /> send your
                    children money.
                </p>
                <button type="button" className="btn btn-primary btn-block btn-lg" onClick={ doneClicked }>
                    Done
                </button>
            </CardView>
        </div>
    )
}

export default AccountConnectedPage