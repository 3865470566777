import moment from 'moment'
import React, { useEffect, useRef, useState, FC } from 'react'

import ErrorBox from '../../pages/signup/components/ErrorBox'

import './DOB.scss'

const useDateComponents = () => {

    const [date, setDate] = useState(null as Date | null)

    const [d, setDay] = useState('')
    const [m, setMonth] = useState('')
    const [y, setYear] = useState('')

    useEffect(() => {
        const dd = parseInt(d)
        const mm = parseInt(m)
        const yyyy = parseInt(y)
        if (isNaN(yyyy) || isNaN(mm) || isNaN(dd)) {
            setDate(null)
            return
        }
        let date = new Date()
        date.setFullYear(yyyy)
        date.setMonth(mm - 1)
        date.setDate(dd)
        if (isNaN(date.getTime())) {
            setDate(null)
            return
        }
        if (moment(date).date() !== dd) {
            setDate(null)
            return
        }
        if (moment(date).month() !== mm - 1) {
            setDate(null)
            return
        }
        if (moment(date).year() !== yyyy) {
            setDate(null)
            return
        }
        setDate(date)
    }, [d, m, y])

    return {
        date,
        d, setDay,
        m, setMonth,
        y, setYear
    }
}
  
// TODO: simplify if-s logic inside this function
const normalizeDateComponent = (
    dateComponent: string,
    min: number | undefined,
    max: number | undefined,
    addZeros: boolean = false
): {value: string, maxReached: boolean, canReachMin: boolean} => {

    const digits = dateComponent
        .replace(/[^\d]/g, '') // remove non digits
        .replace(/^0+/, '') // remove prefix zeros
        .slice(0, max === undefined ? Number.MAX_VALUE : max.toString().length) // trim component with as many digits max has
    
    const value = parseInt(digits, 10)

    const {length} = digits

    if (length < 1) {
        return {
            value: '',
            maxReached: false,
            canReachMin: true
        }
    }

    if (min !== undefined) {
        const nbMissingDigits = min.toString().length - length
        if (nbMissingDigits > 0) {
            const minPossibleValue = parseInt(`${digits}${'9'.repeat(nbMissingDigits)}`, 10)
            if (minPossibleValue < min) {
                return {
                    value: digits,
                    maxReached: false,
                    canReachMin: false
                }
            }
        } else {
            if (value < min) {
                return {
                    value: digits.slice(0, -1),
                    maxReached: false,
                    canReachMin: true
                }
            }
        }
    }

    if (max !== undefined) {
        const nbMissingDigits = max.toString().length - length
        if (nbMissingDigits > 0) {
            const maxPossibleValue = parseInt(`${digits}${'0'.repeat(nbMissingDigits)}`, 10)
            if (!addZeros) {
                return {
                    value: digits,
                    maxReached: maxPossibleValue > max,
                    canReachMin: true
                }
            } else {
                return {
                    value: `${'0'.repeat(nbMissingDigits)}${digits}`,
                    maxReached: maxPossibleValue > max,
                    canReachMin: true
                }
            }
        } else {
            if (value > max) {
                return normalizeDateComponent(digits.slice(0, -1), min, max)
            } else {
                return {
                    value: digits,
                    maxReached: true,
                    canReachMin: true
                }
            }
        }
    }

    return {
        value: digits,
        maxReached: false,
        canReachMin: false
    }

}

interface DOBProps {
    min?: Date
    max?: Date
    onReady: (date: Date | null) => void
}

const DOB: FC<DOBProps> = ({ min, max, onReady }) => {

    const dRef = useRef<HTMLInputElement>(null)
    const mRef = useRef<HTMLInputElement>(null)
    const yRef = useRef<HTMLInputElement>(null)

    const {date, d, setDay, m, setMonth, y, setYear} = useDateComponents()

    const [dayPristine, setDayPristine] = useState(true)
    const [monthPristine, setMonthPristine] = useState(true)
    const [yearPristine, setYearPristine] = useState(true)
    const [pristine, setPristine] = useState(true)

    const [error, setError] = useState('')

    useEffect(() => {
        const {value, maxReached} = normalizeDateComponent(d, 1, 31, true)
        setDay(value)
        if (maxReached && mRef.current !== null) {
            mRef.current.focus()
        }
    }, [d, setDay])

    useEffect(() => {
        const {value, maxReached} = normalizeDateComponent(m, 1, 12, true)
        setMonth(value)
        if (maxReached && yRef.current !== null) {
            yRef.current.focus()
        }
    }, [m, setMonth])

    useEffect(() => {
        const {value, maxReached} = normalizeDateComponent(
            y,
            !min ? undefined : min.getFullYear(),
            !max ? undefined : max.getFullYear(),
            false
        )
        setYear(value)
        if (maxReached && yRef.current !== null) {
            yRef.current.blur()
        }
    }, [y, setYear, min, max])

    useEffect(() => { dayPristine && d !== '' && setDayPristine(false) }, [d, dayPristine])
    useEffect(() => { monthPristine && m !== '' && setMonthPristine(false) }, [m, monthPristine])
    useEffect(() => { yearPristine && y !== '' && setYearPristine(false) }, [y, yearPristine])

    useEffect(() => {
        setPristine(dayPristine || monthPristine || yearPristine)
    }, [dayPristine, monthPristine, yearPristine])

    useEffect(() => {
        if (!date) {
            setError('Invalid date')
        } else if (min && (date.getTime() < min.getTime())) {
            setError(`Min date accepted is ${moment(min).format('DD/MM/YYYY')}`)
        } else if (max && (date.getTime() > max.getTime())) {
            setError(`Max date accepted is ${moment(max).format('DD/MM/YYYY')}`)
        } else {
            setError('')
        }
    }, [date, min, max])

    useEffect(() => {
        if (!error) {
            onReady(date)
        } else {
            onReady(null)
        }
    }, [error, date, onReady])

    const handleDay = (day: string) => {
        setDay(day)
    }

    const handleMonth = (month: string) => {
        setMonth(month)
    }

    const handleYear = (year: string) => {
        setYear(year)
    }

    return (
        <div className="DateOfBirth">
            <div style={{display: 'flex', flexDirection: 'row', width: 'auto', justifyContent: 'center'}}>
                <div className="date-component">
                    <input ref={dRef}
                        autoFocus={true}
                        type="number"
                        pattern="\d*"
                        placeholder="DD"
                        style={{width: '2em', paddingLeft: '0px', paddingRight: '0px'}}
                        value={d}
                        onChange={e => handleDay(e.currentTarget.value)}
                    />
                </div>
                <div className="date-component">
                    <input ref={mRef}
                        type="number"
                        pattern="\d*"
                        placeholder="MM"
                        style={{width: '2em', marginLeft: '21px', paddingLeft: '0px', paddingRight: '0px'}}
                        value={m}
                        onChange={e => handleMonth(e.currentTarget.value)}
                    />
                </div>
                <div className="date-component">
                    <input ref={yRef}
                        type="number"
                        pattern="\d*"
                        placeholder="YYYY"
                        style={{width: '3em', marginLeft: '21px', paddingLeft: '0px', paddingRight: '0px'}}
                        value={y}
                        onChange={e => handleYear(e.currentTarget.value)}
                    />
                </div>
            </div>
            <div hidden={pristine} style={{ marginTop: '10px', marginBottom: "36px", textAlign: "center" }}>
                <ErrorBox errorMessage={error} />
            </div>
        </div>
    )
}

export default DOB