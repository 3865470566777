import React, { useEffect } from 'react'

import * as CSS from 'csstype'
import CardView from '../../components/CardView/CardView'
import '../../components/Page/Page.css'
import { Link } from 'react-router-dom'

import useTitle from '../../common/useTitle'
import { postAnalytics, postDismiss, setBackButtonHidden, setLogoutButtonHidden } from '../../common/postMessage'

import OnboardingManager from './OnboardingManager'
import {ReactComponent as Welcome } from '../../assets/images/onboarding/welcome.svg'

export interface Props {
    action?: () => void
}

const StartPage: React.FC<Props> = ({ action }) => {

    useTitle('')

    useEffect(() => {
        setBackButtonHidden(true)
        setLogoutButtonHidden(false)
        postAnalytics("Guided Onboarding - Welcome")

        OnboardingManager.getInstance().markAsViewed();
    }, [])

    return (
        <div className="Page" id="StartPage">

            <CardView>

                <div>
                    <Welcome style={{ marginTop: '10px', marginBottom: '20px' }} />
                    <h1 style={{ fontSize: '22px', fontWeight: 'bold', marginBottom: '30px' }}>
                        Welcome to the family!
                    </h1>
                    <p style={{ fontSize: '18px', marginBottom: '30px' }}>
                        We’re so excited to have your family become a part of ours.
                    </p>
                    <p style={{ fontSize: '18px', marginBottom: '30px' }}>
                        We want you to get the most out of Spriggy, let us show you how.
                    </p>
                    <p>
                        <Link to="/onboarding/info">
                            <button className="btn btn-primary btn-lg btn-block">
                                Sure, let's go!
                            </button>
                        </Link>
                    </p>
                    <p>
                        <button onClick={ () => { postDismiss() }} style={{fontSize: '18px', fontFamily: 'Bariol'}} className="btn btn-link btn-lg btn-block">
                            Maybe later
                        </button>
                    </p>
                </div>

            </CardView>
        </div>
    )
}

export default StartPage
