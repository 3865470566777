import { useState, useEffect } from "react"

import SignUpManager from '../../pages/signup/SignUpManager'
import { ValidatedAddress } from "../../common/contexts/model/Parent"

/**
 * Query validated available addresses for given user input.
 *
 * @param input a query of at least 5 chars
 * @returns Fist param: a list of addresses. Second param: An optional error message. Third param: A cancel function.
 */
export const useValidatedAddresses = (input?: string): [ValidatedAddress[] | undefined, string | undefined, string | undefined] => {

    const [validatedAddresses, setValidatedAddresses] = useState(undefined as ValidatedAddress[] | undefined)
    const [error, setError] = useState(undefined as string | undefined)
    const [hint, setHint] = useState(undefined as string | undefined)

    useEffect(() => {

        var cancelled = false

        setHint(undefined)
        setError(undefined)
        setValidatedAddresses(undefined)

        if (input === undefined) {
            return
        }

        if (input.length < 5) {
            let remaining = 5 - input.length
            let s = remaining > 1 ? 's' : ''
            setHint(`${remaining} more character${s} to go!`)
            return
        }

        setHint('Loading addresses...')

        SignUpManager.getInstance().getAddressSuggestions(input)
            .then((addresses) => addresses || [])
            .then((addresses) => !cancelled && setValidatedAddresses(addresses))
            .catch(() => setError('Error while loading addresses'))
            .finally(() => setHint(undefined))

        return () => { cancelled = true }

    }, [input])

    return [validatedAddresses, hint, error]
}