
import { Action, action } from "easy-peasy"

export interface ChildModel {

    firstName: string
    lastName: string

    cardType: number
    
    dob: string
    activeScreen: string
    userName: string
    password: string
  
    setFirstName: Action<ChildModel, string>
    setLastName: Action<ChildModel, string>
    setCardType: Action<ChildModel, number>
    setDOB: Action<ChildModel, string>
    
    setUserName: Action<ChildModel, string>
    setPassword: Action<ChildModel, string>

    setActiveScreen: Action<ChildModel, string>
    resetChild: Action<ChildModel>
}


const Child: ChildModel = {
    firstName: "",
    lastName : "",
    cardType: 0,
    dob: "",
    userName: "",
    password: "",


    activeScreen : "AddChildPage",

    setFirstName: action((state, payload) => {
        state.firstName = payload
    }),
    setLastName: action((state, payload) => {
        state.lastName = payload
    }),   
    setCardType: action((state, payload) => {
        state.cardType = payload
    }),
    setDOB: action((state, payload) => {
        state.dob = payload
    }),       
   
    setUserName: action((state, payload) => {
        state.userName = payload
    }),   
    setPassword: action((state, payload) => {
        state.password = payload
    }),  

    setActiveScreen: action((state, payload) => {
        state.activeScreen = payload
    }),      
    resetChild: action((state,) => {
        state.firstName = ""
        state.lastName = ""
        state.cardType = 0
        //we wont reset address for the second or more children
        state.dob =  ""
        state.activeScreen = "AddChildPage"
    }),       
}

export default Child;