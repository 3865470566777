import { v4 as uuidv4 } from 'uuid'

type promiseResolve = (result: any) => void
type promiseReject = (result: any) => void

export default class PromiseRegistry {
    private static instance: PromiseRegistry
    private registry: Map<string, Map<string, [promiseResolve, promiseReject]>>

    private constructor() {
        this.registry = new Map()
    }

    static getInstance(): PromiseRegistry {
        if (!PromiseRegistry.instance) {
            console.log("PromiseRegistry instance not set.")
            PromiseRegistry.instance = new PromiseRegistry()
        }
        return PromiseRegistry.instance
    }

    private getMessageRegistry(message: string): Map<string, [promiseResolve, promiseReject]> {
        var messageRegistry = this.registry.get(message)

        if (messageRegistry === undefined) {
            console.log("Registry was not set.")
            messageRegistry = new Map()
            this.registry.set(message, messageRegistry)
        }

        return messageRegistry
    }

    public registerPromise(message: string, resolve: promiseResolve, reject: promiseReject): string {
        var messageRegistry = this.getMessageRegistry(message)
        var reference = uuidv4()
        messageRegistry.set(reference, [resolve, reject])
        return reference
    }

    public retrieveHandlers(message: string, reference: string): [promiseResolve, promiseReject] {
        var messageRegistry = this.getMessageRegistry(message)

        var handlers = messageRegistry.get(reference)

        if (handlers === undefined) {
            console.log("Handlers were not in registry for " + reference)
            return [() => {}, () => {}]
        }

        return handlers
    }
}
