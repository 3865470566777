import React from 'react'
import * as CSS from 'csstype'
import './CardView.css'

type CardProps = {
    style?: CSS.Properties
}

const CardView: React.FC<CardProps> = ({style, children}) => {
    return (
        <div
            className="CardView"
            style={style}
        >
            { children }
        </div>
    )
}

export default CardView