import React, { HTMLAttributes } from 'react'
import * as CSS from 'csstype'
import PassCode from '../../../components/Passcode'

import './Passcode.css'
import 'react-simple-keyboard/build/css/index.css'
import '../../../components/Page/Page.css'

import CardView from '../../../components/CardView/CardView'

const linkStyle: CSS.Properties = {  
    color:'#1ea3ce',
    fontWeight: 'bold',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0px'
}

interface PasscodeProps extends HTMLAttributes<HTMLDivElement> {
    initialValue?: string
    onPasscodeValid: (passcode: string) => void
    onSkip?: () => void
}

const Passcode: React.FC<PasscodeProps> = ({ initialValue, children, onPasscodeValid, onSkip, ...props }) => {
    return (

        <div {...props}>

            <CardView>

                {children}

                <PassCode
                    name="passcode"
                    initialValue={initialValue}
                    style={{marginBottom: '25px'}}
                    onComplete={newPasscode => setTimeout(() => onPasscodeValid(newPasscode), 0)}
                />
                
                <button style={linkStyle} onClick={() => onSkip && onSkip()} hidden={!onSkip}>Skip</button>

            </CardView>

        </div>
    )
}

export default Passcode