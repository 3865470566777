import React, { useEffect, useRef, useState } from "react";
import "../../components/Page/Page.css";
import * as CSS from "csstype";

import MenuButton from "../../components/MenuButton/MenuButton";

import debitCard1x from "../../assets/images/image1x/debit_card.png";
import debitCard2x from "../../assets/images/image2x/debit_card.png";
import debitCard3x from "../../assets/images/image3x/debit_card.png";

import bankAccount1x from "../../assets/images/image1x/bank.png";
import bankAccount2x from "../../assets/images/image2x/bank.png";
import bankAccount3x from "../../assets/images/image3x/bank.png";

import paypal1x from "../../assets/images/image1x/paypal.png";
import paypal2x from "../../assets/images/image2x/paypal.png";
import paypal3x from "../../assets/images/image3x/paypal.png";

import { postAnalytics } from "../../common/postMessage";
import useTitle from "../../common/useTitle";
import { Client } from "braintree-web";
import PaymentManager from "./PaymentManager";
import { Link, withRouter } from "react-router-dom";

declare const braintree: any

export interface Props {
  action?: () => void;
}

declare global {
  interface Window {
      flags?: {
          paypal?: boolean
      }
  }
}

const useClient = (): Client | null => {
  const [client, setClient] = useState(null as Client | null)
  useEffect(() => {
      PaymentManager
          .getInstance()
          .getToken()
          .then(authorization => braintree.client.create({ authorization }))
          .then(client => setClient(client))
  }, [])
  return client
}

export default withRouter(({ history }) => {
  useTitle("Connected Account");

  useEffect(() => {
    postAnalytics(" PWA Change Connected Account");
  }, []);

  const paypalContainer = useRef<HTMLDivElement>(null);

  const paypalOn = window.flags && window.flags.paypal === true
  const client = useClient()
  const isOnboarding = PaymentManager.getInstance().getOnboardingStatus()

  return (
    <div className="Page" id="ChoosePaymentMethodPage">
      <p style={descriptionStyle}>
      To get the most out of Spriggy, you are going to need some money in your Parent Wallet. Future Top Ups, Scheduled Top Ups and Membership Fees will come from this account.
      </p>
      <MenuButton
        title="Debit Card"
        description="Top Ups with a debit card are free and arrive within 10 mins."
        logoSrc={debitCard1x}
        logoSrcSet={
          debitCard1x + " 1x," + debitCard2x + " 2x," + debitCard3x + " 3x"
        }
        href="/payment/addDebitCard"
      />
      {(paypalOn) && (
        <MenuButton
          title="PayPal"
          description="Top Ups with PayPal incur a 1.5% surcharge and arrive within 10 mins."
          logoSrc={paypal1x}
          logoSrcSet={paypal1x + " 1x," + paypal2x + " 2x," + paypal3x + " 3x"}
          href="/payment/addPayPal"
        />
      )}

      <MenuButton
        title="Bank Account"
        description="Top Ups with a bank account are free and take 3 business days to arrive."
        logoSrc={bankAccount1x}
        logoSrcSet={bankAccount1x + " 1x," + bankAccount2x + " 2x," + bankAccount3x + " 3x"}
        href="/payment/AddBankAccount"
      />
      {(isOnboarding) &&
        <Link to="/onboarding/connect_account_dismissal">
          <button style={{fontSize: '18px', fontFamily: 'Bariol'}} className="btn btn-link btn-lg btn-block">
            I'll connect an account later
          </button>
        </Link>
      }
      <div id="tokendiv"></div>
    </div>
  )
})

const descriptionStyle: CSS.Properties = {
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0px",
  textAlign: "center",
  color: "#333b44",
  margin: "24px 16px 36px 16px"
};
