import React, { useState } from 'react'
import FilledButton from '../../../components/FilledButton/FilledButton'
import * as CSS from 'csstype'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import DOB from '../../../components/DOB/DOB'

import { makeStyles } from '@material-ui/core/styles'

import { useStoreActions, Actions, useStoreState  } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'

import moment from 'moment'
import useTitle from '../../../common/useTitle'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "8px",

  },
  paper: {
    margin: "16px",
    padding: "15px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const descriptionStyle: CSS.Properties = {
  fontSize: "17px",
  lineHeight: "24px",
  letterSpacing: "0.36px",
  textAlign: "center",
  color: "#333b44",
  marginBottom: "0px",
  marginTop: '25px'
}

const textBold: CSS.Properties = {
  fontWeight: "bold"
}

export interface Props {
  action?: () => void
}

const DetailsDOBPage: React.FC<Props> = ({ action }, ...rest) => {
  const classes = useStyles(rest)
  
  const {firstName} = useStoreState(state => state.Parent)
  const storeSetDOB = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setDOB)
  const storeSetActiveScreen = useStoreActions((actions: Actions<StoreModel>) => actions.Parent.setActiveScreen)
  
  //hook
  const [selectedDate, setSelectedDate] = useState(null as Date | null)

  useTitle('Sign Up Spriggy')

  //action
  const handleClick = () => {
    if (selectedDate !== null) {
      storeSetDOB(moment(selectedDate).format("YYYY-MM-DD"))
      storeSetActiveScreen("DetailsEmailPage")
      return action ? action() : null
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12}>
          <p className="TitleLabel">
            And when is your birthday{ (firstName) ? (`, ${firstName}`) : ('') }?
        </p>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginTop: '20px', marginBottom: '45px'}}>
            <DOB
              min={moment().subtract(99, 'years').startOf('day').toDate()}
              max={moment().subtract(18, 'years').endOf('day').toDate()}
              onReady={setSelectedDate}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <FilledButton title="Next" action={(e) => handleClick()} invalid={selectedDate === null}/>
        </Grid>
        <Grid item xs={12}>
          <p style={descriptionStyle}>
            <span style={textBold}>Note:</span> You need to be at least 18 years old to sign children up to Spriggy
          </p>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DetailsDOBPage
