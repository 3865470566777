import React, {useState} from 'react'
import './TextInput.css'

type TextInputProps = {
    title: string,
    validator?: (rawInputValue: string) => void,
    modifier?: (rawInputValue: string) => string
}

const TextInput: React.FC<TextInputProps> = ({title, validator, modifier}) => {

    const [rawTextInput, setRawTextInput] = useState("")

    const InputEvent = (e: React.FormEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value

        if (modifier) {
            setRawTextInput(modifier(inputValue))
        } else {
            setRawTextInput(inputValue)
        }

        if(validator) {
            validator(inputValue)
        }
    }

    return (
        <div className="TextInput form-group">
            <label className="TextInputTitle" style={{ opacity : !rawTextInput  ? 0 : 1 }} >
                {title}
            </label>
            <input 
                className="TextInputField form-control" 
                type="text" 
                placeholder={title} 
                value={rawTextInput}
                onChange={InputEvent}
            />
        </div>
    )
}

export default TextInput