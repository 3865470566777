import { ClientApi } from '../../common/ApiClient'

const URL_MARK_VIEWED = '/on_boarding/guided_onboarding/status'

export default class OnboardingAPI extends ClientApi {
    async markOnboardingViewed(): Promise<boolean> {
        try {
            const http = await this.http
            const confirmationResponse = await http.post(URL_MARK_VIEWED)
            return confirmationResponse.status == 200
        } catch (error) {
            console.log('Error marking onboarding as viewed.')
            console.log(error)
            return false
        }
    }
}
