import React, {FC, HTMLAttributes, useState, useEffect} from 'react'
import { ValidatedAddress } from '../../common/contexts/model/Parent'
import Spinner from '../Spinner/Spinner'
import {fullAddress} from '../../common/utils/formatters'
import './ValidatedAddresses.scss'

interface ValidatedAddressesProps extends HTMLAttributes<HTMLDivElement> {
    addresses?: ValidatedAddress[]
    onSelectAddress: (validatedAddress: ValidatedAddress) => void
    onCantFindAddress: () => void
}

const ValidatedAddresses: FC<ValidatedAddressesProps> = ({
    addresses,
    onSelectAddress,
    onCantFindAddress,
    ...props
}) => {

    const [once, setOnce] = useState(false)
   
    useEffect(() => {
        if (once === true && addresses !== undefined) {
            setOnce(true)
        }
    }, [addresses, once])

    return (
        <div className="ValidatedAddresses" {...props}>
            {(addresses !== undefined) ? (
                <ul className="ValidatedAddresses-items">
                    {addresses && addresses.map(address => (
                        <li key={address._hash} className="ValidatedAddresses-item">
                            <button className="ValidatedAddresses-cta" onClick={() => onSelectAddress(address)}>
                                {fullAddress(address)}
                            </button>
                        </li>
                    ))}
                    <li className="ValidatedAddresses-item ValidatedAddresses-item--error">
                        <button className="ValidatedAddresses-cta" onClick={() => onCantFindAddress()}>
                            I can’t find my address!
                        </button>
                    </li>
                </ul>
            ) : (once) ? (<Spinner/>) : (null)}
        </div>
    )
}

export default ValidatedAddresses