import React, { useState } from 'react'
import './CardDesign.css'

import cardBlack1x from '../../assets/images/card-designs/black.png'
import cardBlack2x from '../../assets/images/card-designs/black@2x.png'
import cardBlack3x from '../../assets/images/card-designs/black@3x.png'
import cardBlue1x from '../../assets/images/card-designs/blue.png'
import cardBlue2x from '../../assets/images/card-designs/blue@2x.png'
import cardBlue3x from '../../assets/images/card-designs/blue@3x.png'
import cardWonderwoman1x from '../../assets/images/card-designs/wonder-woman.png'
import cardWonderwoman2x from '../../assets/images/card-designs/wonder-woman@2x.png'
import cardWonderwoman3x from '../../assets/images/card-designs/wonder-woman@3x.png'
import cardSuperman1x from '../../assets/images/card-designs/superman.png'
import cardSuperman2x from '../../assets/images/card-designs/superman@2x.png'
import cardSuperman3x from '../../assets/images/card-designs/superman@3x.png'
import cardFlash1x from '../../assets/images/card-designs/flash.png'
import cardFlash2x from '../../assets/images/card-designs/flash@2x.png'
import cardFlash3x from '../../assets/images/card-designs/flash@3x.png'
import cardBatman1x from '../../assets/images/card-designs/batman.png'
import cardBatman2x from '../../assets/images/card-designs/batman@2x.png'
import cardBatman3x from '../../assets/images/card-designs/batman@3x.png'
import cardPink1x from '../../assets/images/card-designs/pink.png'
import cardPink2x from '../../assets/images/card-designs/pink@2x.png'
import cardPink3x from '../../assets/images/card-designs/pink@3x.png'
import cardWhite1x from '../../assets/images/card-designs/white.png'
import cardWhite2x from '../../assets/images/card-designs/white@2x.png'
import cardWhite3x from '../../assets/images/card-designs/white@3x.png'

import tickImage from '../../assets/icon/greentick.svg'

export enum CardDesignAPIType {
    Black = 1,
    Blue = 2,
    WonderWoman = 3,
    Superman = 4,
    Flash = 5,
    Batman = 6,
    Pink = 7,
    White = 8
}

const cardDesignName = (cardDesign: CardDesignAPIType): String | undefined => {
    switch (cardDesign) {
        case CardDesignAPIType.Black:
            return 'Spriggy Black'
        case CardDesignAPIType.Blue:
            return 'Spriggy Blue'
        case CardDesignAPIType.WonderWoman:
            return 'WonderWoman'
        case CardDesignAPIType.Superman:
            return 'Superman'
        case CardDesignAPIType.Flash:
            return 'Flash'
        case CardDesignAPIType.Batman:
            return 'Batman'
        case CardDesignAPIType.Pink:
            return 'Pink'
        case CardDesignAPIType.White:
            return 'White'
    }
}

const CardDesignArray = [
    [],
    [cardBlack1x, cardBlack2x, cardBlack3x],
    [cardBlue1x, cardBlue2x, cardBlue3x],
    [cardWonderwoman1x, cardWonderwoman2x, cardWonderwoman3x],
    [cardSuperman1x, cardSuperman2x, cardSuperman3x],
    [cardFlash1x, cardFlash2x, cardFlash3x],
    [cardBatman1x, cardBatman2x, cardBatman3x],
    [cardPink1x, cardPink2x, cardPink3x],
    [cardWhite1x, cardWhite2x, cardWhite3x]
]

interface CardDesignProps {
    type: CardDesignAPIType,
    tickVisiable?: boolean,
    action?: (e: any) => void
}

const CardDesign: React.FC<CardDesignProps> = ({type, action, tickVisiable}) => {

    let srcString = ""
    let srcsetString = ""

    let [loaded, setLoaded] = useState(false)
    
    if (type !== 0) {
        srcsetString = (CardDesignArray[type][0] + ' 1x,' +
            CardDesignArray[type][1] + ' 2x,' +
            CardDesignArray[type][2] + ' 3x')
        srcString = CardDesignArray[type][0]
    }

    return (
        <button className={`CardDesign ${loaded ? 'CardDesign--loaded' : ''}`} onClick={action}>
            <img
                className="card-visual"
                style={{width: '100%'}}
                alt={`${cardDesignName(type) || 'Unknown type of Spriggy'} card`}
                src={srcString}
                srcSet={srcsetString}
                onLoad={() => setLoaded(true)}
            />
            <img className={`tick ${tickVisiable? 'tick--on' : 'tick--off'}`} src={tickImage} alt="Green Tick" />
        </button>

    )
}

export default CardDesign