
import { Action, action } from "easy-peasy"

export interface ValidatedAddress {
    _hash: string
    building_name: string
    flat_mumber: string
    flat_number_prefix: string
    gnaf_id: string
    latitude: number
    level_number: string
    level_type: string
    level_type_full: string
    longitude: number
    number_first: string
    number_last: string
    postcode: string
    state: string
    street_latitude: number
    street_line: string
    street_longitude: number
    street_name: string
    street_suffix: string
    street_suffix_full: string
    street_type: string
    street_type_full: string
    suburb: string
}

export interface ManualAddress {
    address_line1: string
    city: string
    state: AustralianState
    postcode: string
}

export type AustralianState = 'ACT' | 'NSW' | 'NT' | 'QLD' | 'TAS' | 'VIC' | 'WA'

export interface ParentModel {
    firstName: string
    lastName: string
    dob?: string
    email: string
    password: string
    referralCode?: string
    mobile: string
    validatedAddress?: ValidatedAddress
    manualAddress?: ManualAddress

    passcode?: string
    activeScreen?: string

    setFirstName: Action<ParentModel, string>
    setLastName: Action<ParentModel, string>
    setDOB: Action<ParentModel, string>
    setEmail: Action<ParentModel, string>
    setPassword: Action<ParentModel, string>
    setReferralCode: Action<ParentModel, string>
    setActiveScreen: Action<ParentModel, string>
    setMobile: Action<ParentModel, string>
    setValidatedAddress: Action<ParentModel, ValidatedAddress>
    setManualAddress: Action<ParentModel, ManualAddress>
    
    setPasscode: Action<ParentModel, string>
}

const Parent: ParentModel = {
    firstName: "",
    lastName : "",
    dob: "",
    email: "",
    password: "",
    referralCode : "",
    mobile : "",
    validatedAddress: undefined,
    manualAddress: undefined,

    activeScreen : "DetailsFirstNamePage",
    passcode : "",

    setFirstName: action((state, payload) => {
        state.firstName = payload
    }),
    setLastName: action((state, payload) => {
        state.lastName = payload
    }),   
    setDOB: action((state, payload) => {
        state.dob = payload
    }),       
    setEmail: action((state, payload) => {
        state.email = payload
    }),      
    setPassword: action((state, payload) => {
        state.password = payload
    }),     
    setReferralCode: action((state, payload) => {
        state.referralCode = payload
    }),   
    setActiveScreen: action((state, payload) => {
        state.activeScreen = payload
    }), 
    setMobile: action((state, payload) => {
        state.mobile = payload
    }), 
    setValidatedAddress: action((state, payload) => {
        state.validatedAddress = payload
    }), 
    setManualAddress: action((state, payload) => {
        state.manualAddress = payload
    }),

    setPasscode: action((state, payload) => {
        state.passcode = payload
    }),       
}

export default Parent;