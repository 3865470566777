import React, { useEffect, useState, useContext } from 'react'
import { __RouterContext } from "react-router"
import CardView from '../../components/CardView/CardView'
import TextInput from '../../components/TextInput/TextInput'
import NumberInput from '../../components/NumberInput/NumberInput'
import IconedTitle from '../../components/IconedTitle/IconedTitle'
import * as CSS from 'csstype'
import '../../components/Page/Page.css'
import PaymentManager from './PaymentManager'
import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import { StoreModel } from '../../common/contexts/model/Index'

import bankAccount1x from '../../assets/images/image1x/bank.png'
import bankAccount2x from '../../assets/images/image2x/bank.png'
import bankAccount3x from '../../assets/images/image3x/bank.png'
import { postAnalytics } from '../../common/postMessage'
import useTitle from '../../common/useTitle'

const overridePageStyle: CSS.Properties = {
  textAlign: 'left'
}

const errorInput: CSS.Properties = {
  fontSize: '13px',
  color: 'red'
}

export interface Props {
  action?: () => void
}

const AddBankAccountPage: React.FC<Props> = ({ action }: Props, ...rest) => {

  const routerContext = useContext(__RouterContext)

  useTitle('Connected Account')

  useEffect(() => {
    postAnalytics('PWA Change Connected Account To Bank Account')
  }, [])

  const storeGetBankDetails = useStoreState(state => state.BankDetails)
  const storeSetBSB = useStoreActions((actions: Actions<StoreModel>) => actions.BankDetails.setBSB)
  const storeSetAccount = useStoreActions((actions: Actions<StoreModel>) => actions.BankDetails.setaccount)
  const storeSetName = useStoreActions((actions: Actions<StoreModel>) => actions.BankDetails.setname)

  const [nameTouched, setNameTouched] = React.useState(false)

  const [saving, setSaving] = useState(false)


  const [nameValidated, setNameValidated] = useState(false)
  const nameValidator = (rawInputValue: string) => {
    const nameReg = /^[a-zA-Z ]{4,35}$/
    if (nameReg.test(rawInputValue)) {
      setNameValidated(true)
    } else {
      setNameValidated(false)
    }
    setNameTouched(true)
    storeSetName(rawInputValue)
  }

  const [bsbValidated, setBsbValidated] = useState(false)
  const [accountValidated, setAccountValidated] = useState(false)

  const bsbValidator = (rawInputValue: string) => /^[0-9]{6}$/.test(rawInputValue)
  const accountValidator = (rawInputValue: string) => /^[0-9]{6,10}$/.test(rawInputValue)

  const saveDetails = () => {

    if (saving) {
      return
    }

    setSaving(true)

    PaymentManager.getInstance().UpdateBankDetail(storeGetBankDetails).then(success => {
      if (success) {
        setTimeout(() => routerContext.history.push(PaymentManager.getInstance().getSuccessUrl()), 150)
      } else {
        throw new Error('failed')
      }
    }).catch(error => {
      setTimeout(() => routerContext.history.push('/payment/error'), 150)
    }).finally(() => {
      setSaving(false)
    })
  }

  const invalid = !bsbValidated || !accountValidated || !nameValidated

  return (
    <div className="Page" style={overridePageStyle}>

      <CardView>

        <IconedTitle
          title="Connect your bank account"
          discription="Top Ups will take 3 business days to land in your Parent Wallet."
          logoSrc={bankAccount1x}
          logoSrcSet={bankAccount1x + ' 1x,' + bankAccount2x + ' 2x,' + bankAccount3x + ' 3x'}
          withLock={true}
        />

        <TextInput title="Name of Account Holder" validator={nameValidator} />
        {(nameTouched === true && nameValidated === false) ? (
          <div style={errorInput}>Enter a valid name fo at least 4 characters.</div>
        ) : ("")}

        <NumberInput
          title="BSB Number"
          validator={bsbValidator}
          onValidityChange={(valid) => setBsbValidated(valid)}
          onChange={(value) => storeSetBSB(value)}
          error="Enter a valid BSB number consisting of 6 digits"
        />

        <NumberInput
          title="Account Number"
          validator={accountValidator}
          onValidityChange={(valid) => setAccountValidated(valid)}
          onChange={(value) => storeSetAccount(value)}
          error="Enter a valid Account number consisting of 6 to 10 digits"
        />

        <div className="row mt-4">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              disabled={ invalid || saving }
              onClick={() => saveDetails()}
            >{ saving ? (
              'Saving...'
            ) : (
              'Save My Bank Account'
            )} </button>
          </div>
        </div>

      </CardView>

    </div>
  )
}

export default AddBankAccountPage
