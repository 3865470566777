import React, { useEffect, useRef, useState } from 'react'
import FilledButton from "../../../components/FilledButton/FilledButton"
import * as CSS from 'csstype'

import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'

import InputValidator, {cleanNameChars} from '../../../common/utils/InputValidator'

import ErrorBox from '../components/ErrorBox'

import Style from '../../../common/style/Style'
import useTitle from '../../../common/useTitle'

const descriptionStyle: CSS.Properties = {
  fontSize: "17px",
  lineHeight: "24px",
  letterSpacing: "0.36px",
  textAlign: "center",
  color: "#333b44",
  marginBottom: "0px",
  marginTop: '25px'
}

const textBold: CSS.Properties = {
  fontWeight: "bold"
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "8px",

  },
  paper: {
    margin: "16px",
    padding: "15px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

export interface Props {
  action?: () => void
}

const DetailsFirstNamePage: React.FC<Props> = ({ action }, ...rest) => {

  const classes = useStyles(rest)
  const inputElement = useRef<HTMLInputElement>(null)

  const storeGetChild = useStoreState(state => state.Child)
  const storeFirstName = useStoreActions((actions: Actions<StoreModel>) => actions.Child.setFirstName)
  const storeLastName = useStoreActions((actions: Actions<StoreModel>) => actions.Child.setLastName)

  const [firstName, setFirstName] = useState(storeGetChild.firstName)
  const [firstNamePristine, setFirstNamePristine] = useState(true)
  const [firstNameErr, setFirstNameErr] = useState('')
  const handleFirstName = (newFirstName: string) => {
    const cleanFirstName = cleanNameChars(newFirstName).slice(0, 40)
    if (firstNamePristine) {
      setFirstNamePristine(false)
    }
    const err = InputValidator.firstName(cleanFirstName)
    setFirstNameErr(err || '')
    setFirstName(cleanFirstName)
  }

  const [lastName, setLastName] = useState(storeGetChild.lastName)
  const [lastNamePristine, setLastNamePristine] = useState(true)
  const [lastNameErr, setLastNameErr] = useState('')
  const handleLastName = (newLastName: string) => {
    const cleanLastName = cleanNameChars(newLastName).slice(0, 40)
    if (lastNamePristine) {
      setLastNamePristine(false)
    }
    const err = InputValidator.lastName(cleanLastName)
    setLastNameErr(err || '')
    setLastName(cleanLastName)
  }

  useTitle('Add My Child')

  useEffect(() => {
    storeFirstName(firstName)
  }, [storeFirstName, firstName])

  useEffect(() => {
    storeLastName(lastName)
  }, [storeLastName, lastName])


  //action - click
  const handleClick = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    action && action()
  }

  return (
    <React.Fragment>

      <Paper className={classes.paper}>
        
        <Grid container>

          <Grid item xs={12}>
            <p className="TitleLabel">
              What is your child’s name?
            </p>
          </Grid>

          <Grid item xs={12}>
            <input
              ref={inputElement}
              type="text"
              className="PlainInput"
              style={Style.inputStandardStyle}
              placeholder="First Name"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              autoFocus={true}
              value={firstName}
              onChange={e => handleFirstName(e.currentTarget.value)}
            />
            <div style={{ marginBottom: "36px", textAlign: "left" }}>
              {(!firstNameErr) ? ('') : (
                <ErrorBox errorMessage={firstNameErr} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <input
              type="text"
              className="PlainInput"
              style={Style.inputStandardStyle}
              placeholder="Last Name"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              value={lastName}
              onChange={e => handleLastName(e.currentTarget.value)}
            />
            <div style={{ marginBottom: "36px", textAlign: "left" }}>
              {(!lastNameErr) ? ('') : (
                <ErrorBox errorMessage={lastNameErr} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <FilledButton title="Next" action={(e) => handleClick(e)} invalid={(firstNamePristine || !!firstNameErr) || (lastNamePristine || !!lastNameErr)} />
          </Grid>

          <Grid item xs={12}>
            <p style={descriptionStyle}>
              <span style={textBold}>Note:</span> Children need to be 6-17 years old.
            </p>
          </Grid>

        </Grid>

      </Paper>

    </React.Fragment>
  )
}

export default DetailsFirstNamePage
