import { ClientApi } from '../../common/ApiClient'

const URL_ACCOUNTS = '/donations/accounts'
const URL_DONATE = '/donations/donate'
const URL_CONFIRM_DONATE = '/donations/flags'

export interface DonationSourceAccount {
    id: number
    name: string
    accountType: string
    balance: string
}

export interface DonateResponse {
    firstDonation: boolean
}

export interface DonationSourceAccountsResponse {
    accounts: DonationSourceAccount[]
}

export class CharityApi extends ClientApi {
    async getAccounts(): Promise<DonationSourceAccount[]> {
        try {
            const http = await this.http
            const accountsResponse = await http.get<DonationSourceAccountsResponse>(URL_ACCOUNTS)
            return accountsResponse.data.accounts
        } catch (error) {
            throw error
        }
    }

    async makeDonation(amount: string, name: string, accountId: number): Promise<boolean> {
        try {
            const http = await this.http
            const donationResponse = await http.post<DonateResponse>(URL_DONATE, {amount: amount, name: name, accountId: accountId})
            return donationResponse.data.firstDonation
        } catch (error) {
            throw error
        }
    }

    async confirmDonation(name: string, flag: boolean): Promise<boolean> {
        try {
            const http = await this.http
            const confirmationResponse = await http.post(URL_CONFIRM_DONATE, {name: name, flag: flag})
            return confirmationResponse.status == 200
        } catch (error) {
            throw error
        }
    }
}