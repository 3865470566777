import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import { getEnvironment } from './pages/utils'
import PromiseRegistry from './common/promiseRegistry'

import './index.scss'

declare global {
    interface Window {
        receiveTopupResult: (reference: string, isSuccess: boolean, wasCancelled: boolean) => void
    }
}

window.receiveTopupResult = (reference: string, isSuccess: boolean, wasCancelled: boolean) => {
      console.log("Reference is " + reference)
      console.log("Success is " + isSuccess)
      var [resolve, reject] = PromiseRegistry.getInstance().retrieveHandlers('requestTopUp', reference)

      resolve([isSuccess, wasCancelled])
}

Sentry.init({
    dsn: 'https://30700ef8ce134571a67bc35e40596e02@sentry.io/1792997',
    environment: getEnvironment()
})

//"Basic ZXlKaGJHY2lPaUpJVXpJMU5pSXNJbVY0Y0NJNk1UVTVOemc0T1Rjek5pd2lhV0YwSWpveE5UWTJNelV6TnpNMmZRLmV5SnBaQ0k2TWpJeE9UVjkuRWJUbTJMYlpuaU1KQ3ZrVkpNZ043eTZLMkR1RmhGbnFIVjhEZklsUWZFQTo="

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
