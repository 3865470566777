import * as CSS from 'csstype'

export default class Style {

    static inputStandardStyle: CSS.Properties = {
      fontSize: "17px",
      textAlign: "left",
    }


    static spaceStyle: CSS.Properties = {
        width: "100%",
        height: "30px"
      }


}
