import { useState, useEffect, useRef, MutableRefObject } from "react"
import { DonationSourceAccount, CharityApi } from "./api"
import { StandardError } from '../../common/error'
import { API_TIMEOUT } from './Constants' 

export function useAccounts(): [boolean, null | StandardError, null | DonationSourceAccount[]] {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null as null | { errorCode: number, message: string })
    const [accounts, setAccounts] = useState(null as null | DonationSourceAccount[])
    useEffect(() => {
        setIsLoading(true)
        CharityApi.getInstance<CharityApi>().getAccounts()
            .then(accounts => setAccounts(accounts))
            .catch(error => setError(error))
            .finally(() => setIsLoading(false))
    }, [])
    return [isLoading, error, accounts]
}

export function useCreateDonation(name: string, accountId: number, onFinish: (error: StandardError | null, firstDonation: boolean, amount: string) => void): [boolean, MutableRefObject<(amount: string) => void>] {
    const [isLoading, setIsLoading] = useState(false)
    const trigger = useRef((amount: string) => {
        if (!isLoading) {
            setIsLoading(true)
            setTimeout(() => {
                CharityApi.getInstance<CharityApi>().makeDonation(amount, name, accountId)
                    .then(firstDonation => {
                        setIsLoading(false)
                        onFinish(null, firstDonation, amount)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        onFinish(error, false, amount)
                    })
            }, API_TIMEOUT)
                
        }
    })
    return [isLoading, trigger]
}

export function useConfirmDonation(name: string, onFinish: (err: StandardError | null, flag: boolean) => void): [boolean, MutableRefObject<(flag: boolean) => void>] {
    const [isLoading, setIsLoading] = useState(false)
    const trigger = useRef((flag: boolean) => {
        if (!isLoading) {
            setIsLoading(true)
            setTimeout(() => {
                CharityApi.getInstance<CharityApi>().confirmDonation(name, flag)
                    .then(() => {
                        setIsLoading(false)
                        onFinish(null, flag)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        onFinish(error, flag)
                    })
            }, API_TIMEOUT)
        }
    })
    return [isLoading, trigger]
}