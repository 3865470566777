import React from 'react'
import './IconedTitle.css'

import lock1x from '../../assets/images/image1x/lock.png'
import lock2x from '../../assets/images/image2x/lock.png'
import lock3x from '../../assets/images/image3x/lock.png'

type IconedTitleProps = {
    title: string,
    discription: string,
    logoSrc: string,
    logoSrcSet: string,
    withLock?: boolean
}

const IconedTitle: React.FC<IconedTitleProps> = (props) => {
    return (
        <div className="IconedTitle">
            <div className="d-flex align-items-top flex-0">
                <div className="IconedTitleIconWrapper">
                    <img
                        alt=""
                        src={props.logoSrc}
                        srcSet={props.logoSrcSet}
                    />
                </div>
                <div className="IconedTitleTitleWrapper">
                    <p>{props.title}</p>
                    <p>{props.discription}</p>
                </div>
            </div>
            {props.withLock && (
                <div className="LockWrapper">
                    <img
                        alt=""
                        src="assets/images/image1x/lock.png"
                        srcSet={`${lock1x} 1x, ${lock2x} 2x, ${lock3x} 3x`}
                    />
                </div>
            )}

        </div>

    )
}

export default IconedTitle