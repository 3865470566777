import {ValidatedAddress, ManualAddress} from '../contexts/model/Parent'

export const fullAddress = (address: ValidatedAddress): string => {
    return [
        address.street_line, [
            address.suburb,
            address.state,
            address.postcode
        ].join(' ')
    ].join(', ')
}

export const manualAddress = (address: ManualAddress): string => {
    return [
        address.address_line1,[
            address.city,
            address.state,
            address.postcode
        ].join(' ')
    ].join(', ')
}

export const anyAddress = (validated: ValidatedAddress | null, manual: ManualAddress | null): string | null => {
    if (validated !== null) {
        return fullAddress(validated)
    }
    if (manual !== null) {
        return manualAddress(manual)
    }
    return null
} 