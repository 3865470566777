import React from 'react'
import './FilledButton.css'
import * as CSS from 'csstype'

type FilledButtonProps = {
    title?: string,
    bgcolor?: string,
    color?: string,
    invalid?: boolean,
    action?: (...args: any[])=>void
}

const FilledButton: React.FC<FilledButtonProps> = (props) => {

    let backgroundColor: string | undefined

    if(props.bgcolor){
        backgroundColor = props.bgcolor
    }

    if(props.invalid === true){
        backgroundColor = "#bcbcbc"
    }
    
    const overrideStyle: CSS.Properties = {
        backgroundColor: backgroundColor,
        color: props.color
    }

    return (
        <button
            className="FilledButton"
            onClick={(e) => !props.invalid && props.action && props.action(e)}
            disabled={props.invalid}
        >
            <div style={overrideStyle}>
                {props.title}
            </div>
        </button>
        
    )
}

export default FilledButton