import React, { useEffect, useState, useContext } from 'react'
import useTitle from '../../common/useTitle'

import { useStoreState } from 'easy-peasy'

import CardView from "../../components/CardView/CardView"
import FilledButton from "../../components/FilledButton/FilledButton"

import * as CSS from 'csstype'
import '../../components/Page/Page.css'

import { __RouterContext } from "react-router"
// import icon1x from '../../assets/images/image1x/confirmMobileIcon.png'
import icon2x from '../../image2x/confirmMobileIcon.png'
import icon3x from '../../image3x/confirmMobileIcon.png'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core"
import SignUpManager from './SignUpManager'
import InputValidator from '../../common/utils/InputValidator'

const cardStyle: CSS.Properties = {
  padding: "24px"
}

const titleStyle: CSS.Properties = {
  marginTop: "24px",
  marginBottom: "24px"
}

const descriptionStyle: CSS.Properties = {
  fontSize: "17px",
  lineHeight: "24px",
  letterSpacing: "0.36px",
  textAlign: "center",
  color: "#333b44",
  marginBottom: "32px"
}

const btnResend: CSS.Properties = {
  color:'#1ea3ce',
  float:'left',
  appearance: 'none',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '0px'
}

const inputStandardStyle: CSS.Properties = {
  fontSize: "17px",
  lineHeight: "24px",
  textAlign: "left",
  height: "30px",
  float:'left',
  width: "60%",
  marginRight: "10px"
}

const inputContainer: CSS.Properties = {  
  marginBottom: "50px"
}

const linkStyle: CSS.Properties = {  
  color:'#1ea3ce',
  fontWeight:"bold",
  appearance: 'none',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '0px'
}

const alertTitleStyle: CSS.Properties = {
  fontFamily: 'Bariol-Bold' 
}
const alertDescriptionStyle: CSS.Properties = {
  fontFamily: 'Bariol' 
}

const VerifyMobileNumberPage: React.FC = (props) => {
  const routerContext = useContext(__RouterContext)

  const { mobile } = useStoreState(state => state.Parent)

  const [obfuscatedNumber, setObfuscatedNumber] = useState('')

  useTitle('Sign Up Spriggy')

  useEffect(() => {
    if (!mobile) {
      setObfuscatedNumber('04 XXXX XXXX')
    } else {
      setObfuscatedNumber(`04 XXXX X${mobile.slice(-3)}`)
    }
  }, [mobile])

  //action
  const gotoNextPage = () => {
    routerContext.history.push("/signup/passcode")
  }

  //action
  const handleResend = () => {
    // TODO: Re-send
  }

  //action
  const handleExternalLink = () => {
    // TODO: Handle external link
  };    

  const [open, setOpen] = useState(false)
  const [valid, setValid] = useState(false)
  const [authCode, setAuthCode] = useState('')
  function handleClose() {
    setOpen(false)
  }

  const handleAuthCode = ({ currentTarget: { value }}: React.FormEvent<HTMLInputElement>) => {
    const numberDigits = value.replace(/[^0-9]/g, '')
    setAuthCode(numberDigits)
    let isValid = InputValidator.numberLengthValidation(numberDigits, 6)
    setValid(isValid)
  }

  return (
    <div className="Page" id="AddChildSuccessPage">

      <CardView style={{...cardStyle, position: 'relative', overflow: 'hidden' }}>

        <span style={{position: 'relative', display: 'inline-block'}}>
          <span style={{ display: 'block', overflow: 'hidden', width: '94px', marginLeft: '40px' }}>
            <img srcSet={`${icon2x} 2x, ${icon3x} 3x`} alt="Green Tick" />
          </span>
          <span style={{position: 'absolute', top: '55px', right: '-999px', height: '30px', backgroundColor: '#33A3CE', left: '50%', marginLeft: '67px' }}></span>
        </span>

        <p className="TitleLabel" style={titleStyle}>
          Confirm your mobile number
        </p>
        
        <div style={descriptionStyle}>
          <p style={{ marginBottom: '25px' }}>
            We’ve sent an SMS to ({obfuscatedNumber})<br/>When your receive it, enter the
            code below
          </p>
          <div style={{...inputContainer, borderBottom: '1px solid black', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'stretch' }}>
            <input
              className="PlainInput"
              style={{...inputStandardStyle, float: 'none', border: 'none', flexGrow: 1}}
              type="number"
              pattern="\d*"
              maxLength={6}
              value={authCode}
              onChange={handleAuthCode}
              placeholder=""
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              autoFocus={true}
            />
            <button style={{...btnResend, padding: '0px'}} onClick={handleResend}>Resend SMS</button>
          </div>
        </div>

        <FilledButton title="Next" action={() => {
          if (valid) {
            setOpen(false)
            SignUpManager
              .getInstance()
              .verifyToken(mobile, authCode)
              .then(() => {
                setTimeout(() => {gotoNextPage()}, 0)
              })
              .catch((error) => {
                setAuthCode("")
                setOpen(true)
                setValid(false)
              }) 
          } else {

          }
        }} 
        invalid = {!valid}
        />

      </CardView>

      <p style={{...descriptionStyle, margin: '25px'}}>
        Haven’t received your code? You may have entered your mobile number
        incorrectly. Please try entering it again by going back or<br/>
        <button onClick={handleExternalLink} style={linkStyle}>Contact Support</button>  for help
      </p>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle>
          <div style={alertTitleStyle}>Incorrect code entered</div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
              <span style={alertDescriptionStyle}>The code you have entered does not match the one we’ve sent you via SMS. </span>      
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <span style={alertTitleStyle}>Try Again</span>           
          </Button>
        </DialogActions>

      </Dialog>

    </div>
  )
}

export default VerifyMobileNumberPage
