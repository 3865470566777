import React, { useState, useEffect } from 'react'

import '../../components/Page/Page.css'
import CardView from '../../components/CardView/CardView'
import './Charity.scss'
import charitySaveTheChildren1x from '../../assets/images/image1x/charity_save_the_children.png'
import charitySaveTheChildren2x from '../../assets/images/image2x/charity_save_the_children.png'
import charitySaveTheChildren3x from '../../assets/images/image3x/charity_save_the_children.png'
import SaveTheChildrenLink from './SaveTheChildrenLink'
import useTitle from '../../common/useTitle'
import useUser from '../../common/useUser'
import { useConfirmDonation } from './hooks'
import { TITLE_CHARITY_CONFIRMATION, NAME_CHARITY_SAVE_THE_CHILDREN } from './Constants'
import { setBackButtonHidden } from '../../common/postMessage'
import { StandardError } from '../../common/error';

interface Props {
    action: (success: boolean, flag: boolean) => void,
}

const ConfirmCharity: React.FC<Props> = ({action}) => {
    const finishedConfirmDonation = (error: StandardError | null, flag: boolean) => {
        action(error === null, flag)
    }

    const user = useUser() 
    const [isLoading, confirmTrigger] = useConfirmDonation(NAME_CHARITY_SAVE_THE_CHILDREN, finishedConfirmDonation)
    const [isPositive, setIsPositive] = useState(true)

    useTitle(TITLE_CHARITY_CONFIRMATION)

    useEffect(() => {
        setBackButtonHidden(isLoading)
    }, [isLoading])

    const renderUserName = () => {
        if (user && user.firstName) {
            return (user.firstName + ",")
        }
        return ""
    }

    const onPositiveClicked = () => {
        setIsPositive(true)
        confirmTrigger.current && confirmTrigger.current(true)
    }

    const onNegativeClicked = () => {
        setIsPositive(false)
        confirmTrigger.current && confirmTrigger.current(false)
    }

    return (
        <div className="Page">
            <CardView style={{ padding: "16px", paddingTop: "24px" }}>
                <img 
                src={charitySaveTheChildren1x}
                srcSet={charitySaveTheChildren1x+' 1x,'+charitySaveTheChildren2x+' 2x,'+charitySaveTheChildren3x+' 3x'}
                />
                <p style={{textAlign: "left", fontWeight: "bold", marginTop: "24px"}}>Hi  {renderUserName()}</p>
                <div style={{ textAlign: "left" }}>
                    <p>Together we can give over 1,000 Aussie kids a better chance to learn, grow and develop toward  a brighter future. To make this happen, we need your help to raise $100,000 for {SaveTheChildrenLink("https://www.savethechildren.org.au/Our-Stories", "Save The Children")} by February 2020 - together we can do it.</p>
                    <p>If your family can help out, please join in. Every dollar helps and it’s a great chance for your kids to help other kids!</p>
                </div>
                <button type="button" className="btn btn-positive btn-block" style={{marginBottom: 16}} onClick={onPositiveClicked} disabled={isLoading}>
                    {isLoading && isPositive ? "Saving preference..." : "Yes, my family will give"}
                </button>
                <button type="button" className="btn btn-negative btn-block" onClick={onNegativeClicked} disabled={isLoading}>
                    {isLoading && !isPositive ? "Saving preference..." : "No, thank you"}   
                    
                </button>
                <p style={{textAlign: "left", fontSize: "13px", marginTop: "16px"}}>
                <b>Disclaimer</b>: If you opt in to give, it will become available in your child’s app. They will be able to give to Save The Children from their own Card or Savings. By opting in, you approve any donations your child may make.
                </p>
            </CardView>
        </div>
    )
}

export default ConfirmCharity