import React, { useEffect } from 'react'
import CardView from '../../../components/CardView/CardView'
import FilledButton from '../../../components/FilledButton/FilledButton'
import CardDesign, { CardDesignAPIType } from '../../../components/CardDesign/CardDesign'
import * as CSS from 'csstype'

import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import { StoreModel } from '../../../common/contexts/model/Index'
import useTitle from '../../../common/useTitle'


const cardView: CSS.Properties = {
    paddingTop: '24px'
}

const titleStyle: CSS.Properties = {
    fontFamily: 'Bariol-Bold',
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0.45px',
    textAlign: 'center',
    color: '#333b44',
    marginBottom: "20px"
}

const emphasisStyle: CSS.Properties = {
    fontFamily: 'Bariol-Bold'
}


export interface Props {
    action?: () => void
}

const CardDesignSelectionPage: React.FC<Props> = ({ action }) => {

    const storeGetChild = useStoreState(state => state.Child)
    const storeSetCardType = useStoreActions((actions: Actions<StoreModel>) => actions.Child.setCardType)
    const { firstName } = storeGetChild

    const [currentSelection, setSelection] = React.useState(storeGetChild.cardType || null as CardDesignAPIType | null)

    useTitle('Add My Child')

    useEffect(() => {
        if (currentSelection !== null) {
            storeSetCardType(currentSelection)
        }
    }, [currentSelection, storeSetCardType])

    interface ICard  {
        type: CardDesignAPIType,
        name: string
    }

    const cards: ICard[] = [
        { type: CardDesignAPIType.Black,        name: 'Black' },
        { type: CardDesignAPIType.Blue,         name: 'Blue' },
        { type: CardDesignAPIType.WonderWoman,  name: 'WonderWoman' },
        { type: CardDesignAPIType.Superman,     name: 'Superman' },
        { type: CardDesignAPIType.Flash,        name: 'Flash' },
        { type: CardDesignAPIType.Batman,       name: 'Batman' },
        { type: CardDesignAPIType.Pink,         name: 'Pink' },
        { type: CardDesignAPIType.White,        name: 'White' },
    ]

    return (
        <div
            className="Page"
            id="ChooseCardDesignPage"
        >
            <CardView style={cardView} >
                <p style={titleStyle}>
                    Select a card design for <br /><span style={emphasisStyle}>{firstName}</span>
                </p>
                <p style={{fontSize: '17px', lineHeight: '20px', letterSpacing: '0.61px'}}>
                    This card can be temporarily locked at any time from the parent app
                </p>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '407px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '25px' }}>
                    {cards.map((card, i) => (
                        <CardDesign key={`card-design-${i}`} type={card.type} action={() => setSelection(card.type)} tickVisiable={card.type === currentSelection} />
                    ))}
                </div>
                <FilledButton
                    title="Next"
                    action={() => action && action()}
                    invalid={currentSelection === null}
                />
            </CardView>
        </div>
    )
}

export default CardDesignSelectionPage