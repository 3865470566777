import React, {useState, useEffect} from 'react'
import '../TextInput/TextInput.css'

type NumberInputProps = {
    title: string,
    validator?: (rawInputValue: string) => boolean,
    modifier?: (rawInputValue: string) => string,
    onValidityChange: (valid: boolean) => void,
    onChange: (value: string) => void,
    error: string
}

const NumberInput: React.FC<NumberInputProps> = ({title, validator, modifier, error, onValidityChange, onChange}) => {
    const [rawTextInput, setRawTextInput] = useState("")
    const [errorText, setErrorText] = useState("")
    const [pristine, setIsPristine] = useState(true)
    const [hasFocus, setHasFocus] = useState(false)

    const InputEvent = (e: React.FormEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value
        if(modifier){
            setRawTextInput(modifier(inputValue))
        } else {
            setRawTextInput(inputValue)
        }
        if (pristine && hasFocus) {
            setIsPristine(false)
        }
    }

    useEffect(() => {
        if (!validator) {
            return
        }
        if (!validator(rawTextInput)) {
            setErrorText(error)
            onValidityChange(false)
        } else {
            setErrorText('')
            onValidityChange(true)
            onChange(rawTextInput)
        }
    }, [rawTextInput, onValidityChange, onChange])

    return (
        <div className="TextInput form-group">
            <label
                className="TextInputTitle"
                style={{ opacity : rawTextInput === ""  ? 0 : 1 }}
            >
                {title}
            </label>
            <input 
                className="TextInputField form-control" 
                type="number"
                pattern="\d*"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
                placeholder={title} 
                value={rawTextInput}
                onChange={InputEvent}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
            />
            {(errorText && !pristine)?(
                <p style={{fontSize: '13px', color: 'red', margin: '0px', marginTop: '8px'}}>{errorText}</p>
            ):(null)}
            {/*<p className="TextInputHint"></p>*/}
        </div>
    )
}

export default NumberInput