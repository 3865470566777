import { useState, useEffect } from "react";

declare global {
    interface Window { 
        user?: {firstName: string | null}
    }
}

export default function useUser() {
    const [user, setUser] = useState<{firstName: string | null} | null>(null)
    useEffect(() => {
        var t = 0
        var dt = 50
        var timeoutDuration = 10000
        var pollTimeout: NodeJS.Timeout | null = null
        function poll() {
            if (t >= timeoutDuration) {
                // handle timeout
                setUser({firstName: 'There'})
                if (pollTimeout) {
                    clearTimeout(pollTimeout)
                }
                return
            } 
            if (window.user !== undefined) {
                setUser(window.user)
                if (pollTimeout) {
                    clearTimeout(pollTimeout)
                }
            } else {
                t += dt
                pollTimeout = setTimeout(poll, dt)
            }
        }
        poll()
        return () => { 
            //clear time out
            if (pollTimeout) {
                clearTimeout(pollTimeout)
            }
        }
    }, [])
    return user
}