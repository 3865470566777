import React, { useEffect } from 'react'

import * as CSS from 'csstype'
import CardView from '../../components/CardView/CardView'
import '../../components/Page/Page.css'
import { Link } from 'react-router-dom'

import useTitle from '../../common/useTitle'
import { postAnalytics, postDismiss } from '../../common/postMessage'

export interface Props {
    action?: () => void
}

const TopUpModalDismissal: React.FC<Props> = ({ action }) => {

    useEffect(() => {
        postAnalytics("Guided Onboarding - Top Up Modal Dismissal")
    }, [])

    return (
        <div className="Page" id="TopUpModalDismissal">

            <CardView>

                <div style={{ paddingTop: '10px' }}>
                    <h1 style={{ fontSize: '20px', fontFamily: 'Bariol-Bold', marginBottom: '20px' }}>
                        No worries, but we thought you should know...
                    </h1>
                    <p style={{ marginBottom: '20px' }}>
                        You won't be able to send money to your kids without topping up.
                    </p>
                    <p style={{ marginBottom: '40px' }}>
                        To do this later, tap the floating Parent Wallet icon and top up to start sending your kids money.                     </p>
                    <p>
                        <button onClick={ () => { postDismiss() } } className="btn btn-primary btn-lg btn-block">
                            Ok, thanks!
                        </button>
                    </p>
                    <p>
                        <Link to="/onboarding/complete_topup">
                            <button style={{ fontSize: '18px', fontFamily: 'Bariol' }} className="btn btn-link btn-lg btn-block">
                                Actually, I'll top up now
                            </button>
                        </Link>
                    </p>
                </div>

            </CardView>
        </div>
    )
}

export default TopUpModalDismissal
