const nameForbiddenChars = /[^a-zÀ-ÿ '.-]/gi

export const cleanNameChars = (name: string): string => name
    .replace(nameForbiddenChars, '')
    .replace(/\s{2,}/g, ' ')
    .replace(/\.{2,}/g, '.')
    .replace(/'{2,}/g, '\'')
    .replace(/-{2,}/g, '-')

export default class InputValidator {

    static mobileValidation(val: string): {hint?: string, error?: string } {
        if (/[^\d]/.test(val)) {
            return {error:'Mobile numbers can only contain 10 digits'}
        }
        if (val.length >= 1 && !val.startsWith('0')) {
            return {error:'Mobile numbers start with 04'}
        }
        if (val.length >= 2 && !val.startsWith('04')) {
            return {error: 'Mobile numbers start with 04'}
        }
        if (val.length !== 10) {
            return {hint:`${10-val.length} more ${val.length < 9 ? 'digits' : 'digit'} to go!`}
        }
        return {}
    }

    static bsblValidation(val: string): boolean {
        var regexp = new RegExp("^[0-9]{6}$")
        var isValid = regexp.test(val)
        return isValid
    }
    
    static emailValidation(val: string): boolean {
        var regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        var isValid = regexp.test(val)
        return isValid
    }

    static isEmpty(val: string): boolean {
        // eslint-disable-next-line no-useless-escape
        var regexp = new RegExp("^(?!\s*$).+")
        var isValid = regexp.test(val)
        return !isValid
    }

    static numberLengthValidation(val: string, length: number): boolean {
        var regexp = new RegExp("^[0-9]{{0}}$".replace("{0}", length.toString(10)))
        return regexp.test(val)
    }

    static firstName(firstName: string): string | null {
        const minLength = 2
        if (nameForbiddenChars.test(firstName)) {
            return 'First name can only contain letters, commas, points or hyphens.'
        } else if (firstName.length < minLength) {
          return `First name must have at least ${minLength} characters.`
        } else {
          return null
        }
    }

    static lastName(firstName: string): string | null {
        const minLength = 2
        if (nameForbiddenChars.test(firstName)) {
            return 'Last name can only contain letters, commas, points or hyphens.'
        } else if (firstName.length < minLength) {
          return `Last name must have at least ${minLength} characters.`
        } else {
          return null
        }
    }
}
