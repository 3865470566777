import React, { useEffect } from 'react'

import { withRouter, Redirect } from "react-router"
import { useHasPayment } from "./PaymentManager"
import Spinner from "../../components/Spinner/Spinner"

const Index = withRouter(({ history }) => {
  const hasPayment = useHasPayment()
  return (
    <React.Fragment>
      { hasPayment === null ? (
        <Spinner/>
      ) : hasPayment === false ? (
        <Redirect to="/payment/ChoosePaymentMethod" />
      ) : (
        <Redirect to="/payment/connectedAccounts"/>
      )}
    </React.Fragment>
  )
})

export default Index
