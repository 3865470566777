
export default function getBaseURL(hostname: string): string {
    console.log("Hostname is " + hostname)
    switch (hostname) {

        case 'apppwauat.azurewebsites.net':
            return 'http://apiuiuat.azurewebsites.net/api/'

        case 'localhost':
        case '10.0.2.2':
            // return 'http://localhost:8000/api/'

        case 'pwa.client.test.spriggy.com.au':
            return 'https://api.client.test.spriggy.com.au/api/'

        default:
            return 'https://api.client.spriggy.com.au/api/'
    }

}

// ENVIRONMENT

export type Environment = 'production' | 'dev'

export const getEnvironment = (): Environment => {

    switch (window.location.hostname.toLowerCase()) {

        case 'pwa.client.test.spriggy.com.au':
            return 'dev'

        case 'pwa.client.spriggy.com.au':
            return 'production'

        case 'localhost':
            return 'dev'

        default:
            return 'dev'
    }
}


// MISC

function deepClone(sourceObject: any): any {
    if (typeof sourceObject !== 'object' || sourceObject instanceof Date) {
        return sourceObject
    }
    var clone: any = sourceObject instanceof [].constructor ? [] : {}
    for (let key in sourceObject) {
        if (!sourceObject.hasOwnProperty(key)) {
            continue
        }
        if (typeof sourceObject[key] === 'function') {
            continue
        }
        clone[key] = deepClone(sourceObject[key])
    }
    return clone
}

export const filterProps = (props: any, exclProps: string[]): string => {
    if (!props) {
        return ''
    }
    if (typeof props !== 'object' || props.constructor === [].constructor) {
        return ''
    }
    let extraProps = deepClone(props)
    for (let key in extraProps) {
        if (exclProps.indexOf(key) > -1) {
            delete extraProps[key]
        }
    }
    if (Object.keys(extraProps).length === 0) {
        return ''
    }
    return `, Other Props: "${JSON.stringify(extraProps)}"`
}
