import Axios, { AxiosInstance } from 'axios'
import getBaseURL from '../utils'
import { ParentModel, ValidatedAddress } from '../../common/contexts/model/Parent'
import { ChildModel } from '../../common/contexts/model/Child'
import { saveAuthToken } from '../../common/postMessage'

const URL_REGISTER_MOBILE = 'sign_up/register_mobile'
const URL_SAVE_USER_PROFILE = 'sign_up/save_user_profile'
const URL_LONG_TERM_TOKEN = "user/long_term_token"
const URL_VERIFY_TOKEN = "user/verify_token"
const URL_ADD_CHILD = "sign_up/add_child"
const URL_VALIDATED_ADDRESS = "parent/address/autocomplete"

interface StatusResponse {
    status: string
}

interface TokenResponse {
    duration: number,
    token: string
}

interface IUser {
    id: number
}

interface ISaveUserResponse extends StatusResponse {
    active_user: IUser
    status: string
}

const encodeLongTermToken = (token: string): string => {
    return `Basic ${ btoa(token + ':') }`
}

const decodeLongTermToken = (token: string): string => {
    return atob(token.replace(/^Basic /, '')).replace(/:$/, '')
}

class SignUpManager {

    private static instance: SignUpManager
    private networkInstance: AxiosInstance
    private authToken: string = ''

    public tk(): string {
        return decodeLongTermToken(this.authToken)
    }

    private constructor() {
        this.networkInstance = Axios.create({
            baseURL: getBaseURL(window.location.hostname),
            timeout: 30000
        })
    }

    static getInstance(): SignUpManager {
        if (!SignUpManager.instance) {
            SignUpManager.instance = new SignUpManager()
        }

        return SignUpManager.instance
    }

    private setAuthorizationHeader = (authToken: string) => {
        this.authToken = encodeLongTermToken(authToken)
        this.networkInstance.defaults.headers['Authorization'] = this.authToken
    }

    //API methods
    public saveMobile(number: string, ___test: boolean | undefined = undefined): Promise<void>{

        const payload = {
            "mobile": number
        }

        return this.networkInstance.post<StatusResponse>(URL_REGISTER_MOBILE, payload)
            .catch((error) => {
                if (error.response.status === 409) {
                    return Promise.reject(error.response.data.info)
                }
                return Promise.reject("Cannot register your mobile at the moment, please try later")
            })
            .then((response) => {
                if(response.data.status === "success") {
                    return Promise.resolve()
                } else {
                    return Promise.reject("Get an unsuccessful")
                }
            })
    }

    public verifyToken(mobile: string, authToken: string): Promise<string> {

        const payload = {
            "mobile": mobile,
            "auth_code": authToken
        }

        return this.networkInstance.post<TokenResponse>(URL_VERIFY_TOKEN, payload)
            .catch((error) => {
                if (error.response.status >= 400 && error.response.status <= 499) {
                    return Promise.reject(error.response.data.info)
                } else {
                    return Promise.reject("Cannot verify your auth code at the monent, please try again later")
                }
            })
            .then((response) => {
                if (response.data.token) {
                    this.setAuthorizationHeader(response.data.token)
                    return Promise.resolve(response.data.token)
                } else {
                    return Promise.reject("Auth code error")
                }
            })
            .then((shortTermToken) => {
                console.log("short term token is " + shortTermToken)
                return this.exchangeLongTermToken(shortTermToken)
            })
    }

    public exchangeLongTermToken(shortTermToken: string): Promise<string> {
        const payload = {
            "one_time_password": shortTermToken
        }

        return this.networkInstance.post<TokenResponse>(URL_LONG_TERM_TOKEN, payload)
            .then((response) => {
                console.log(response.data)
                if (response.data.token) {
                    this.setAuthorizationHeader(response.data.token)
                    return Promise.resolve(response.data.token)
                } else {
                    return Promise.reject("Get long term token error")
                }
            })
    }

    public saveUserProfile(parent: ParentModel): Promise<IUser> {

        var referral_code = (parent.referralCode !== "") ? parent.referralCode : undefined

        const payload = {
            first_name: parent.firstName,
            last_name: parent.lastName,
            dob: parent.dob,
            email: parent.email,
            password: parent.password,
            referral_code: referral_code
        }

        return this.networkInstance.put<ISaveUserResponse>(URL_SAVE_USER_PROFILE, payload)
            .catch((error) => {
                return Promise.reject("Save user profile error")
            })
            .then((response) => {
                if (response.data.status === 'success') {
                    return Promise.resolve(response.data.active_user)
                } else {
                    return Promise.reject("Save user profile error")
                }
            })
    }

    public addChild(child: ChildModel): Promise<void> {

        const payload = {
            "first_name": child.firstName,
            "last_name": child.lastName,
            "dob": child.dob,
            "card_stock_type": child.cardType
        }

        return this.networkInstance.post<StatusResponse>(URL_ADD_CHILD, payload)
            .catch((error) => {
                return Promise.reject("Add child error")
            })
            .then((response) => {
                if (response.data.status === 'success') {
                    return Promise.resolve()
                } else {
                    return Promise.reject("Add child error")
                }
            })
    }

    public getAddressSuggestions(input: string): Promise<ValidatedAddress[] | undefined> {

        const payload = {
            address: input
        }

        return this.networkInstance
            .post<{addresses?: ValidatedAddress[]}>(URL_VALIDATED_ADDRESS, payload)
            .catch(() => Promise.reject('An error occured while loading addresses.'))
            .then(response => response.data.addresses)
    }

}

export default SignUpManager