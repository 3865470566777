import React from 'react'
import CardView from '../CardView/CardView'
import './MenuButton.css'
import * as CSS from 'csstype'
import { Link } from 'react-router-dom'

import chevronRight1x from '../../assets/images/image1x/chevron_right.png'
import chevronRight2x from '../../assets/images/image2x/chevron_right.png'
import chevronRight3x from '../../assets/images/image3x/chevron_right.png'

interface MenuButtonProps {
    title: string,
    description: string,
    logoSrc: string,
    logoSrcSet: string,
    href: string,
    disableLink?: boolean,
    action?: (e: any) => void
}

const cardStyle: CSS.Properties = {
    padding: '0',
    overflow: 'auto',
    height: '97px',
    marginTop: '0px',
    marginBottom: '12px'
}


const MenuButton: React.FC<MenuButtonProps> = ({ logoSrc, logoSrcSet, title, description: discription, href, action, disableLink }: MenuButtonProps) => {

    const menuBox = <CardView style={cardStyle}>
        <div className="MenuButtonIconWrapper">
            <img
                src={logoSrc}
                srcSet={logoSrcSet}
            />
        </div>
        <div className="MenuButtonChevronWrapper">
            <img
                src={chevronRight1x}
                srcSet={chevronRight1x + ' 1x,' + chevronRight2x + ' 2x,' + chevronRight3x + ' 3x'}
            />
        </div>
        <div className="MenuButtonTitleWrapper">
            <p>{title}</p>
            <p>{discription}</p>
        </div>
    </CardView>

    const linkContainer = <Link
        className="MenuButton"
        onClick={action}
        to={href}
    >
        {menuBox}
    </Link>

    const aTagContainer = <a
        href="#"
        onClick={action}
    >
        {menuBox}
    </a>

    return (
        disableLink ? aTagContainer : linkContainer
    )
}

export default MenuButton